import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { useTranslation } from 'react-i18next';
import SuggestionRow from 'src/pages/TranscriptViewPage/components/Suggestions/components/SuggestionRow/SuggestionRow';
import InvoiceIcon from 'src/svgs/InvoiceIcon';
import { getCreateInvoiceUrl } from 'src/utils/url';

export type InvoiceSuggestionProps = {
  trackClickSuggestion: () => void;
};

/**
 * Renders an invoice suggestion, which opens a new tab to the invoices
 * creation page.
 *
 * @example
 * Basic usage:
 * <InvoiceSuggestion
 *   trackClickSuggestions={() => {...}}
 * />
 * @param {Function} trackClickSuggestions
 * The function to track when the suggestion is clicked.
 * @author klim
 */
const InvoiceSuggestion = observer(
  ({ trackClickSuggestion }: InvoiceSuggestionProps): ReactElement => {
    const { t } = useTranslation();
    const { transcriptView } = useMessengerControllerContext();
    const { transcript } = transcriptView;

    if (transcript.type === 'ORPHAN') {
      return <></>;
    }

    return (
      <SuggestionRow
        onClick={() => {
          trackClickSuggestion();
          window.open(
            getCreateInvoiceUrl(transcript.customerToken, transcript.sellerKey),
            '_blank',
          );
        }}
        text={t('SmartSuggestions.invoice')}
        icon={<InvoiceIcon color="#0055CC" />}
      />
    );
  },
);

export default InvoiceSuggestion;
