import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { MarketBanner, MarketButton } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { UnitSetting } from 'src/MessengerTypes';
import SoundPlayer from 'src/components/SoundPlayer/SoundPlayer';
import DeleteIcon from 'src/svgs/DeleteIcon';
import './VoicemailPlayer.scss';

const getVoicemailSuccessText = (
  isVoicemailEnabled: boolean,
  isTextEnabled: boolean,
): string => {
  if (isVoicemailEnabled && isTextEnabled) {
    return t(
      'EditVoicemailPage.custom_voicemail_success_text.v2.voicemail_and_text_enabled',
    );
  }
  if (isVoicemailEnabled) {
    return t(
      'EditVoicemailPage.custom_voicemail_success_text.v2.voicemail_enabled',
    );
  }
  return t('EditVoicemailPage.custom_voicemail_success_text.v2.text_enabled');
};

export type VoicemailPlayerProps = {
  unitSetting: UnitSetting;
  onClickDeleteButton?: () => void;
};

/**
 * Component to play a voicemail.
 *
 * @param {UnitSetting} unitSetting
 * The unit setting to play the voicemail for.
 * @param {() => void} [onClickDeleteButton]
 * Callback to be called when the delete button is clicked.
 * @returns {ReactElement | null}
 */
const VoicemailPlayer = observer(
  ({
    unitSetting,
    onClickDeleteButton,
  }: VoicemailPlayerProps): ReactElement | null => {
    const { settings } = useMessengerControllerContext();
    const { t } = useTranslation();

    const {
      defaultVoicemailGreeting,
      customVoicemailGreeting,
      isEnabled: isVoicemailEnabled,
      isTextEnabled,
    } = unitSetting.voicemail;
    const defaultVoicemail = defaultVoicemailGreeting?.url
      ? settings.voicemails.get(defaultVoicemailGreeting?.url)
      : undefined;
    const customVoicemail = customVoicemailGreeting?.url
      ? settings.voicemails.get(customVoicemailGreeting?.url)
      : undefined;
    const voicemail = customVoicemail || defaultVoicemail;

    if (!voicemail) {
      return null;
    }

    return (
      <>
        <SoundPlayer
          sound={voicemail}
          soundLoadErrorText={t('VoicemailEventCard.load_error')}
          trailingAccessory={
            customVoicemail && (
              <MarketButton
                onClick={() => {
                  onClickDeleteButton?.();
                }}
                rank="tertiary"
                size="small"
              >
                <DeleteIcon slot="icon" />
              </MarketButton>
            )
          }
        />
        {customVoicemail && (isVoicemailEnabled || isTextEnabled) && (
          <MarketBanner className="VoicemailPlayer__banner" variant="info">
            <Trans i18nKey="EditVoicemailPage.custom_voicemail_success_text.template">
              {
                'The following automated message will be added after your greeting: '
              }
              <span className="VoicemailPlayer__message-text">
                “
                {{
                  message: getVoicemailSuccessText(
                    isVoicemailEnabled,
                    isTextEnabled,
                  ),
                }}
                ”
              </span>
              {'.'}
            </Trans>
          </MarketBanner>
        )}
      </>
    );
  },
);

export default VoicemailPlayer;
