import { reaction, makeAutoObservable, runInAction } from 'mobx';
import {
  Attachment,
  Bucket,
  ConsentStatus,
  Cursor,
  ITranscript,
  IUtterance,
  Medium,
  Suggestion,
  Transcript as TranscriptV3,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import type MessengerController from 'src/MessengerController';
import {
  ConversationType,
  LoadingStatus,
  LocalUtterance,
  Photo,
  TranscriptViewItem,
} from 'src/MessengerTypes';
import {
  getContextualEventsBeginTimestamp,
  getUtteranceFromTranscriptViewItem,
  isShallowEqual,
  localUtteranceToTranscriptViewItem,
  mergeUtterancesLists,
  mostRecentUtterance,
  UNKNOWN_TRANSCRIPT_VERSION,
} from 'src/utils/transcriptUtils';
import Api from 'src/api/Api';
import {
  getPhotosFromAttachments,
  getPhotosFromViewItems,
} from 'src/utils/photoUtils';
import {
  addCustomerImageToItems,
  addTimestampToItems,
  extractDialogueUtterancesAndAttachToContextualEvents,
  filterSoftDeletedUtterances,
  mergeUtterancesAndLocalUtterances,
} from 'src/utils/viewItemUtils';
import Logger from 'src/Logger';
import Sound from 'src/stores/objects/Sound';
import { t } from 'i18next';

export type TranscriptData = {
  id: number;
  version?: number;
  bucket?: Bucket;
  contactId?: string;
  medium?: Medium;
  sellerKey?: string;
  isRead?: boolean;
  displayName?: TranscriptV3.IDisplayName;
  previewUtterance?: IUtterance;
  customerTokens?: string[];
  consentStatus?: ConsentStatus;
  isBlocked?: boolean;
  utterances?: IUtterance[];
  contextualEvents?: TranscriptViewItem[];
  localUtterances?: LocalUtterance[];
};

export const mapTranscriptFromProto = ({
  id,
  version,
  contactMethodAndSellerKey,
  details,
}: ITranscript): TranscriptData => ({
  id: id as number,
  version,
  bucket: details?.bucket,
  contactId: contactMethodAndSellerKey?.contactId,
  medium: contactMethodAndSellerKey?.medium,
  sellerKey: contactMethodAndSellerKey?.sellerKey,
  isRead: details?.isRead,
  displayName: details?.displayName,
  previewUtterance: details?.previewUtterance,
  customerTokens: details?.customerTokens
    ? [...details.customerTokens]
    : undefined,
  consentStatus: details?.consentStatus,
  isBlocked: details?.isBlocked,
  utterances: details?.utterances ? [...details.utterances] : undefined,
});

/**
 * Store object that is created once per transcript data object.
 * Responsible for containing state about the given transcript ID
 * Contains actions to update the state and derived state in the form of computeds.
 * Transcript proto is not used directly to simplify reading/writing of data
 * instead of readonly/optional props. As well, this enables the use of cached
 * mobx computeds to avoid defining our own caching logic.
 */
class Transcript {
  private _stores: MessengerController;
  private _api: Api;

  /**
   * The marker used to tell the server from which index to load further older utterances (i.e. the current position in the list).
   * Note that in the console log output of GetTranscriptWithUtterances, if cursor is not present, it is actually present
   * in code and is returned as null. Therefore, we treat both undefined and null as an indicator of end of list.
   */
  backwardCursor?: Cursor | null;

  /**
   * The marker used to tell the server from which index to load further most recent utterances
   * (i.e. the current front position in the list). If missing, then we have the most recent utterance loaded.
   * Note that in the console log output of GetTranscriptWithUtterances, if cursor is not present, it is actually present
   * in code and is returned as null. Therefore, we treat both undefined and null as an indicator of end of list.
   */
  forwardCursor?: Cursor | null;

  /**
   * The version of this transcript we have stored in local state. See proto definitions for details on intricacies of the version.
   */
  version = UNKNOWN_TRANSCRIPT_VERSION;

  /**
   * Status used to show a load/error state for the initial load of the transcript.
   */
  status: LoadingStatus = 'NOT_STARTED';

  /**
   * Status used to show a load/error state for loading additional older utterances.
   */
  loadPrevStatus: LoadingStatus = 'NOT_STARTED';

  /**
   * Status used to show a load/error state for loading additional newer utterances.
   */
  loadNextStatus: LoadingStatus = 'NOT_STARTED';

  /**
   * Status used to show a load/error state for loading additional photo attachments.
   */
  loadPhotoAttachmentsStatus: LoadingStatus = 'NOT_STARTED';

  /**
   * A unique identifier for the transcript. Always exists.
   */
  id: number;

  /**
   * The bucket this transcript belongs to (i.e. active, assistant, etc.).
   */
  bucket?: Bucket;

  /**
   * The contact ID associated with the transcript (i.e. a phone number or email address).
   */
  contactId?: string;

  /**
   * The medium of the contact ID for the transcript (i.e. EMAIL or SMS).
   */
  medium: Medium = Medium.MEDIUM_UNRECOGNIZED;

  /**
   * The seller key associated with the transcript (i.e. the unit token).
   */
  sellerKey = '';

  /**
   * Flag indicating if the transcript has been read.
   */
  isRead?: boolean;

  /**
   * Formatted display names to show to the user.
   */
  displayName: TranscriptV3.IDisplayName = {};

  /**
   * The server chosen utterance to use as a preview for the transcript.
   */
  previewUtterance: IUtterance = {};

  /**
   * The list of customer tokens associated with this transcript.
   */
  customerTokens: string[] = [];

  /**
   * The current consent status for this transcript (i.e. for the contact ID / medium / seller key set).
   */
  consentStatus: ConsentStatus = ConsentStatus.DENIED_DUE_TO_ERROR;

  /**
   * Flag indicating if this transcript is in a blocked state (i.e. merchant is unable to send/receive messages).
   */
  isBlocked?: boolean;

  /**
   * The list of utterances associated with the transcript. Matches the value of `this.utterances` but is updated
   * immediately in applyTranscriptUpdate so that parallel updates have access to the most recent utterances,
   * even if they are not yet displayed in the UI. Intended to only be used internally for the applyTranscriptUpdate method.
   */
  private _utterances: IUtterance[] = [];

  /**
   * The list of utterances associated with the transcript that should be displayed in the UI.
   * In general, this value matches `this._utterances` except while updates are fetched. `this.utterances` is only updated after
   * the changes are ready to be displayed in the UI (i.e. after further async operations complete).
   */
  utterances: IUtterance[] = [];

  /**
   * The list of contextual events that should be displayed in the UI.
   */
  contextualEvents: TranscriptViewItem[] = [];

  /**
   * The list of local utterances that should be displayed in the UI.
   */
  localUtterances: LocalUtterance[] = [];

  /**
   * The list of suggestions that should be displayed in the UI.
   */
  suggestions: Suggestion[] = [];

  /**
   * The nearest upcoming contextual event. Typically, an appointment reminder that's displayed in the UI.
   */
  futureContextualEvent?: TranscriptViewItem;

  /**
   * Mapping of all voicemail IDs to sound instances representing the voicemail, that are loaded for this transcript.
   * Intentionally not observable as voicemail instances are created once per ID and never re-initialized.
   */
  voicemails: Map<number, Sound> = new Map();

  /**
   * Images attached to this transcript, fetched by GetAttachments call.
   * These will be shown in the Photos Gallery.
   */
  photoAttachments: Photo[];

  /**
   * If true, all photo attachments are loaded and we can stop calling GetAttachments.
   */
  allPhotoAttachmentsLoaded = false;

  customerDetailsStatus: LoadingStatus = 'NOT_STARTED';

  /**
   * The utterance ID that the transcript was opened to. Provided when the transcript does not start
   * at the most recent page.
   */
  seekUtteranceId?: number;

  static createFromProto(
    stores: MessengerController,
    transcript: ITranscript,
  ): Transcript {
    return new this(stores, mapTranscriptFromProto(transcript));
  }

  constructor(stores: MessengerController, data: TranscriptData) {
    makeAutoObservable(this, {
      applyTranscriptUpdate: false,
      voicemails: false,
    });

    this._stores = stores;
    this._api = stores.api;
    this.id = data.id;
    this.set(data);
    this.photoAttachments = [];

    // When the last view item changes, load suggestions if not null.
    // null indicates the last view item is not something we can load suggestions for
    reaction(
      () => this.lastViewItemUtteranceId,
      () => this.loadSuggestions(),
    );
    // When the associated customer tokens change, check for a future contextual event to display
    reaction(
      // Use a sorted string to ensure the shallow equality check can be accurate
      () => [...this.customerTokens].sort().join(','),
      () => {
        this.loadFutureContextualEvent();
        this.loadCustomers();
      },
    );
    // When the view items change, create voicemail instances for those that don't already exist
    reaction(
      () => this.viewItems,
      () => {
        this.viewItems.forEach((item) => {
          if (item.dataType !== 'VOICEMAIL') {
            return;
          }
          const attachment =
            item.attachedUtterance?.utterance?.attachments?.[0];
          const { id, url, mimeType, token } = attachment || {};
          if (id && url && !this.voicemails.has(id)) {
            this.voicemails.set(
              id,
              new Sound(this._stores, { id, url, mimeType, token }),
            );
          }
        });
      },
    );
  }

  set = ({
    id,
    version,
    bucket,
    contactId,
    medium,
    sellerKey,
    isRead,
    displayName,
    previewUtterance,
    customerTokens,
    consentStatus,
    isBlocked,
    utterances,
    contextualEvents,
    localUtterances,
  }: Partial<TranscriptData>): void => {
    this.id = id ?? this.id;
    this.version = version ?? this.version;
    this.bucket = bucket ?? this.bucket;
    this.contactId = contactId ?? this.contactId;
    this.medium = medium ?? this.medium;
    this.sellerKey = sellerKey ?? this.sellerKey;
    this.isRead = isRead ?? this.isRead;
    if (displayName) {
      Object.assign(this.displayName, displayName);
    }
    if (previewUtterance) {
      Object.assign(this.previewUtterance, previewUtterance);
    }
    if (
      customerTokens &&
      !isShallowEqual(this.customerTokens, customerTokens)
    ) {
      this.customerTokens = customerTokens;
    }
    this.consentStatus = consentStatus ?? this.consentStatus;
    this.isBlocked = isBlocked ?? this.isBlocked;
    this._utterances = utterances ?? this._utterances;
    this.utterances = utterances ?? this.utterances;
    this.contextualEvents = contextualEvents ?? this.contextualEvents;
    this.localUtterances = localUtterances ?? this.localUtterances;
  };

  // Load photo attachments in case user opens the Photo Gallery from the More menu
  loadPhotosForGallery = (): void => {
    if (this.photos.length === 0) {
      this.loadPhotoAttachments();
    }
  };

  // WARNING: Feature flags may not have been loaded yet when this method is run
  load = async (seekUtteranceId?: number): Promise<void> => {
    this.status = 'LOADING';
    this.seekUtteranceId = seekUtteranceId;
    try {
      const loadPromise = this._load({ seekUtteranceId });
      this.loadCustomers();
      this.loadFutureContextualEvent();
      await loadPromise;

      this.loadPhotosForGallery();
      runInAction(() => {
        this.status = 'SUCCESS';
      });
    } catch {
      runInAction(() => {
        this.status = 'ERROR';
      });
    }
  };

  // Loads utterances, contextual events, and local utterances from the server/IndexedDB and puts these values in state
  // WARNING: Feature flags may not have been loaded yet when this method is run
  _load = async ({
    seekUtteranceId,
    backwardCursor,
    forwardCursor,
  }: {
    seekUtteranceId?: number;
    backwardCursor?: Cursor;
    forwardCursor?: Cursor;
  } = {}): Promise<void> => {
    // Load the list of utterances associated with a transcript
    // Loads a set amount, and then subsequent calls retrieve further pages using the cursors returned
    const [transcript, nextBackwardCursor, nextForwardCursor] =
      await this._api.transcripts.getTranscriptWithUtterances({
        id: this.id,
        cursor: backwardCursor || forwardCursor || undefined,
        seekUtteranceId,
      });

    await this.applyTranscriptUpdate({
      transcript,
      isAtStartOfList: !nextBackwardCursor,
      hasNextUtterances: Boolean(nextForwardCursor),
    });

    // If seekUtteranceId is passed, this is the initial load and should set the cursor result no matter what.
    // If backwardCursor is passed, this is a load more operation, and we should set the cursor result no matter what (i.e. undefined in this case indicates the end of the list has been reached).
    // If no backwardCursor, forwardCursor, or seekUtteranceID is passed, then this is an initial load, and we should set the cursor regardless.
    if (backwardCursor || !forwardCursor || seekUtteranceId)
      this.backwardCursor = nextBackwardCursor;
    // If seekUtteranceId is passed, this is the initial load and should set the cursor result no matter what.
    // If forwardCursor is passed, this is a load more operation, and we should set the cursor result no matter what (i.e. undefined in this case indicates the end of the list has been reached).
    // Setting undefined here for other cases like updates or 'loading more' at the start of the list etc. would remove a valid forward cursor.
    if (forwardCursor || seekUtteranceId)
      this.forwardCursor = nextForwardCursor;
  };

  applyTranscriptUpdate = async ({
    transcript,
    isAtStartOfList,
    hasNextUtterances,
  }: {
    transcript: ITranscript;
    isAtStartOfList?: boolean;
    hasNextUtterances?: boolean;
  }): Promise<void> => {
    // Immediately set the new version to prevent checks against an outdated version number while async logic is computing
    this.set({ version: transcript.version });

    const transcriptData: TranscriptData = mapTranscriptFromProto(transcript);
    // Use and set utterances in `this._utterances` to ensure parallel executions include the most recent utterances
    // If not set, parallel updates would be missing utterances that haven't been set yet while contextual events/local utterances are loaded
    this._utterances = mergeUtterancesLists(
      this._utterances,
      transcriptData.utterances || [],
    );
    const earliestTimestamp = getContextualEventsBeginTimestamp(
      this._utterances,
      isAtStartOfList ?? !this.hasPrevPage,
    );

    const latestUtteranceTimestamp = mostRecentUtterance(
      this._utterances,
    )?.spokenAtMillis;

    // Load all contextual events for all customer tokens associated with a transcript
    // Loads the entire list of contextual events from the earliest utterance to now
    const contextualEvents = await this._api.contextualEvents.get({
      merchantToken: this._stores.user.merchantToken,
      customerTokens: transcriptData.customerTokens || [],
      beginTimestampMillisInclusive: earliestTimestamp,
      endTimestampMillisExclusive:
        (hasNextUtterances && latestUtteranceTimestamp) || Date.now(),
      unitToken: transcriptData.sellerKey || '',
    });

    // Load all saved local utterances from IndexedDB
    const localUtterances: LocalUtterance[] =
      await this._stores.localUtterances.get(
        this.id,
        this._utterances,
        earliestTimestamp,
        hasNextUtterances ? latestUtteranceTimestamp : undefined,
      );

    this.set({
      ...transcriptData,
      utterances: this._utterances,
      contextualEvents,
      localUtterances,
    });
  };

  loadPrevPage = async (): Promise<void> => {
    this.loadPrevStatus = 'LOADING';
    try {
      await this._load({ backwardCursor: this.backwardCursor || undefined });
      this.loadPrevStatus = 'SUCCESS';
    } catch {
      this.loadPrevStatus = 'ERROR';
    }
  };

  loadNextPage = async (): Promise<void> => {
    this.loadNextStatus = 'LOADING';
    try {
      await this._load({ forwardCursor: this.forwardCursor || undefined });
      this.loadNextStatus = 'SUCCESS';
    } catch {
      this.loadNextStatus = 'ERROR';
    }
  };

  clearUtterances = (): void => {
    this._utterances = [];
    this.utterances = [];
    this.contextualEvents = [];
    this.localUtterances = [];
    this.backwardCursor = undefined;
    this.forwardCursor = undefined;
    this.seekUtteranceId = undefined;
  };

  loadSuggestions = async (): Promise<void> => {
    this.suggestions = [];
    // Don't load suggestions if there are still more utterances to be loaded
    if (this.lastViewItemUtteranceId && !this.hasNextPage) {
      const suggestions = await this._api.transcripts.getSuggestions(
        this.lastViewItemUtteranceId,
      );
      runInAction(() => {
        this.suggestions = suggestions;
      });
    }
  };

  // WARNING: Feature flags may not have been loaded yet when this method is run
  loadFutureContextualEvent = async (): Promise<void> => {
    const futureContextualEvent =
      await this._api.contextualEvents.getNearestFutureEvent(
        this._stores.user.merchantToken,
        this.customerTokens,
      );
    runInAction(() => {
      this.futureContextualEvent = futureContextualEvent ?? undefined;
    });
  };

  // WARNING: Feature flags may not have been loaded yet when this method is run
  loadPhotoAttachments = async (pageSize?: number): Promise<void> => {
    if (
      this.loadPhotoAttachmentsStatus === 'LOADING' ||
      this.allPhotoAttachmentsLoaded
    ) {
      return;
    }

    this.loadPhotoAttachmentsStatus = 'LOADING';
    try {
      const [attachments, cursor] = await this._api.transcripts.getAttachments({
        transcriptId: this.id,
        attachmentTypes: [Attachment.AttachmentType.IMAGE],
        attachmentPageSize: pageSize,
        startAfterAttachmentId:
          this.photos.length > 0
            ? this.photos[this.photos.length - 1].attachmentId
            : undefined,
      });

      if (!cursor) {
        this.allPhotoAttachmentsLoaded = true;
      }

      // Photos may be duplicated if user sends multiple requests in a short time span.
      // They must be deduped to ensure that no photo is being shown twice.
      const photos = getPhotosFromAttachments(attachments).filter(
        ({ attachmentId }) =>
          attachmentId != null && !this.photoAttachmentIds.has(attachmentId),
      );

      runInAction(() => {
        this.photoAttachments.push(...photos);
        this.loadPhotoAttachmentsStatus = 'SUCCESS';
      });
    } catch {
      runInAction(() => {
        this.loadPhotoAttachmentsStatus = 'ERROR';
      });
    }
  };

  // Reloads the utterance with the provided ID from the server. If no utterance is already loaded, no-op occurs.
  updateUtterance = async (id: number): Promise<void> => {
    if (this.utterances.some((utterance) => utterance.id === id)) {
      const updatedUtterance = await this._api.transcripts.getUtterance(id);
      this.set({
        utterances: mergeUtterancesLists(this.utterances, [updatedUtterance]),
      });
    }
  };

  addLocalUtterance = async (localUtterance: LocalUtterance): Promise<void> => {
    this.localUtterances.push(localUtterance);
    // Persist local utterance by writing to IndexedDB
    try {
      await this._stores.localUtterances.set(localUtterance);
    } catch {
      Logger.logWithSentry(
        'Transcript:addLocalUtterance - Failed to add local utterance to IndexedDB.',
        'warning',
        { localUtterance },
      );
    }
  };

  updateLocalUtterance = async (
    updatedLocalUtterance: LocalUtterance,
  ): Promise<void> => {
    const clientId = updatedLocalUtterance.utterance.metadata?.clientId;

    // Replace the most recent matching local utterance in state with the updated local utterance
    // There may be multiple local utterances with the same clientId (if the user retried a failed
    // message multiple times). We only want to update the most recent one.
    for (let i = this.localUtterances.length - 1; i >= 0; i--) {
      if (this.localUtterances[i].utterance.metadata?.clientId === clientId) {
        this.localUtterances[i] = updatedLocalUtterance;
        break;
      }
    }

    try {
      // Persist updated local utterance by writing to IndexedDB
      await this._stores.localUtterances.set(updatedLocalUtterance);
    } catch (error) {
      Logger.logWithSentry(
        'Transcript:updateLocalUtterance - Error when attempting to update local utterance in IndexedDB.',
        'warning',
        { error, updatedLocalUtterance },
      );
    }
  };

  removeLocalUtterance = async (utteranceClientId: string): Promise<void> => {
    this.localUtterances = this.localUtterances.filter(
      (localUtterance: LocalUtterance) =>
        localUtterance?.utterance?.metadata?.clientId !== utteranceClientId,
    );
    try {
      // Remove from IndexedDB to persist removal
      await this._stores.localUtterances.remove(utteranceClientId);
    } catch {
      Logger.logWithSentry(
        'Transcript:removeLocalUtterance - Failed to remove local utterance from IndexedDB.',
        'warning',
        { utteranceClientId },
      );
    }
  };

  markAsRead = async (): Promise<void> => {
    if (this.utterances.length > 0) {
      const prevIsRead = this.isRead;
      this.isRead = true;
      try {
        await this._api.transcripts.updateTranscript({
          id: this.id,
          readUntil: this.utterances[this.utterances.length - 1].id,
        });
      } catch (error) {
        this.isRead = prevIsRead;
        throw error;
      }
    }
  };

  markAsUnread = async (): Promise<void> => {
    const prevIsRead = this.isRead;
    this.isRead = false;
    try {
      // A readUntil value of zero is a special case to indicate a transcript is unread
      await this._api.transcripts.updateTranscript({
        id: this.id,
        readUntil: 0,
      });
    } catch (error) {
      this.isRead = prevIsRead;
      throw error;
    }
  };

  block = async (): Promise<void> => {
    const prevIsBlocked = this.isBlocked;
    this.isBlocked = true;
    try {
      await this._api.transcripts.updateTranscript({
        id: this.id,
        isBlocked: true,
      });
    } catch (error) {
      this.isBlocked = prevIsBlocked;
      throw error;
    }
  };

  unblock = async (): Promise<void> => {
    const prevIsBlocked = this.isBlocked;
    this.isBlocked = false;
    try {
      await this._api.transcripts.updateTranscript({
        id: this.id,
        isBlocked: false,
      });
    } catch (error) {
      this.isBlocked = prevIsBlocked;
      throw error;
    }
  };

  requestConsent = async (): Promise<void> => {
    this.consentStatus = await this._api.messaging.requestConsent(this.id);
  };

  // WARNING: Feature flags may not have been loaded yet when this method is run
  loadCustomers = async (): Promise<void> => {
    this.customerDetailsStatus = 'LOADING';
    try {
      await this._stores.customers.loadCustomers(this.customerTokens);
      runInAction(() => {
        this.customerDetailsStatus = 'SUCCESS';
      });
    } catch {
      runInAction(() => {
        this.customerDetailsStatus = 'ERROR';
      });
    }
  };

  setBackwardCursor = (cursor?: Cursor): void => {
    this.backwardCursor = cursor;
  };

  get customerToken(): string {
    return this.customerTokens.length > 0 ? this.customerTokens[0] : '';
  }

  get hasPrevPage(): boolean {
    return Boolean(this.backwardCursor);
  }

  get hasNextPage(): boolean {
    return Boolean(this.forwardCursor);
  }

  get type(): ConversationType {
    if (this.customerTokens.length === 0) {
      return 'ORPHAN';
    } else if (this.customerTokens.length === 1) {
      return 'ORDINARY';
    } else {
      return 'SYNTHETIC';
    }
  }

  get isActive(): boolean {
    return this.bucket === Bucket.ACTIVE;
  }

  // Synchronous function that derives the view items from the utterances, contextual events, and local utterances state
  // Only re-computes when one of these values changes, and doesn't need to refresh all data when one state changes
  get viewItems(): TranscriptViewItem[] {
    // 1. Filter out any soft deleted utterances from the list of utterances
    const nonDeletedUtterances = filterSoftDeletedUtterances(this.utterances);

    // 2. Merge utterances and local utterances together into one list of local utterances
    const mergedUtterances: LocalUtterance[] =
      mergeUtterancesAndLocalUtterances(
        nonDeletedUtterances,
        this.localUtterances,
      );

    // 3. Extract dialogue utterances and attach them to contextual events by checking if the utterance contains the metadata dialogueConversationToken
    const [utterances, contextualEvents] =
      extractDialogueUtterancesAndAttachToContextualEvents(
        mergedUtterances,
        this.contextualEvents,
      );

    // 4. Concat utterances and contextual events together to prepare for sorting to add medium timestamp and customer images.
    const itemsWithoutTimestamp: TranscriptViewItem[] = [
      // Transform utterances from LocalUtterance type to TranscriptViewItem
      ...utterances.map((localUtterance) =>
        localUtteranceToTranscriptViewItem(localUtterance, this.sellerKey),
      ),
      ...contextualEvents,
    ];

    // 5. Sort all the items in chronological order
    itemsWithoutTimestamp.sort((a, b) => a.timestampMillis - b.timestampMillis);

    // 6. Insert medium timestamp between items.
    const items: TranscriptViewItem[] = addTimestampToItems(
      itemsWithoutTimestamp,
      this.medium,
    );

    // 7. Loop through and add customer image to the last item in a group of consecutive customer cards or utterances
    return addCustomerImageToItems(items);
  }

  get lastViewItemUtteranceId(): number | null {
    if (this.viewItems.length === 0) {
      return null;
    }
    const lastViewItem = this.viewItems[this.viewItems.length - 1];
    const utterance = getUtteranceFromTranscriptViewItem(lastViewItem);
    return utterance?.id ?? null;
  }

  /**
   * Photos from local utterances, utterances, and contextual events. Derived from viewItems().
   */
  get utterancePhotos(): Photo[] {
    return getPhotosFromViewItems(this.viewItems);
  }

  /**
   * Photos loaded via GetAttachments. If they contain duplicate photos from utterance photos,
   * those duplicates are filtered out.
   */
  get dedupedPhotoAttachments(): Photo[] {
    return this.photoAttachments.filter(
      ({ attachmentId }) =>
        attachmentId != null && !this.utterancePhotoIds.has(attachmentId),
    );
  }

  /**
   * Set of attachment ids for all utterance photos (this.utterancePhotos)
   */
  get utterancePhotoIds(): Set<number | undefined> {
    return new Set(
      this.utterancePhotos.map(({ attachmentId }) => attachmentId),
    );
  }

  /**
   * Set of attachment ids for all photo attachments fetched via GetAttachments.
   */
  get photoAttachmentIds(): Set<number | undefined> {
    return new Set(
      this.photoAttachments.map(({ attachmentId }) => attachmentId),
    );
  }

  /**
   * All photo attachments. This includes any photos from local utterances, utterances,
   * and contextual events, as well as any additional photos that were loaded in the
   * Photo Gallery.
   */
  get photos(): Photo[] {
    return [...this.utterancePhotos, ...this.dedupedPhotoAttachments];
  }

  /**
   * The title of the transcript, which is typically the customer name. If the transcript
   * is orphaned, try to return a maybe name, and if that is not available, return the regular
   * display name which should be either the phone number or email. If the transcript is ordinary,
   * return the regular display name which is the customer name.
   */
  get title(): string | undefined {
    if (this.type === 'ORPHAN' && this.displayName.maybeName) {
      return t('common.name.maybe', {
        name: this.displayName.maybeName,
      });
    }
    return this.displayName.name || undefined;
  }

  /**
   * Gets most recent server-fetched utterance id.
   * Returns undefined if no utterances are available.
   */
  get lastFetchedUtteranceId(): number | undefined {
    if (!this._utterances.length) return undefined;
    return this._utterances[this._utterances.length - 1].id;
  }

  /**
   * If the transcript contains a missed call or voicemail utterance.
   * This is used to determine if the voicemail customization banner should be shown.
   */
  get hasMissedCallOrVoicemail(): boolean {
    return this.viewItems.some(
      (item) =>
        (item.dataType === 'INBOUND_CALL' &&
          item.attachedUtterance?.utterance.metadata?.inboundCall) ||
        (item.dataType === 'VOICEMAIL' &&
          item.attachedUtterance?.utterance.metadata?.voicemail),
    );
  }

  /**
   * Determines if the transcript has data other than the transcript ID set,
   * by checking for the existence of core pieces of transcript data.
   * Used by the transcript list store to know if we should set data for a transcript.
   * Workaround for a bug where on the initial load of the /t/<transcriptId> path,
   * the transcript may exist but not have data fetched and thus should be set.
   */
  get hasData(): boolean {
    return this.medium !== Medium.MEDIUM_UNRECOGNIZED && this.sellerKey !== '';
  }
}

export default Transcript;
