/**
 * This file may change a lot over the next few weeks. - eblaine, 4/8/20
 *
 * A few stupid utility functions that are too icky for the main data layer.
 * If a routine **is synchronous** and involves complicated array operations
 * or esoteric knowledge of the Conversations API to implement/understand,
 * it might belong here.
 */
import { t } from 'i18next';
import {
  Attachment,
  ConsentStatus,
  ITranscript,
  IUtterance,
  Medium,
  Status,
  Utterance,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import {
  Contact,
  Status as AuxiliaryStatus,
} from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';
import Logger from 'src/Logger';
import {
  CheckoutLink,
  ContextualEventType,
  TranscriptViewItem,
  Feedback,
  GraphQLCheckoutLinkResponse,
  LocalUtterance,
  LocalUtteranceClientId,
  Marketing,
  MediumTimestamp,
  MessageDestination,
  Money,
  Receipt,
  UtterancePreview,
  FeedbackFacet,
} from 'src/MessengerTypes';
import { v4 as uuidv4 } from 'uuid';
import {
  Conversation as SDKConversation,
  SdkMedium,
  Utterance as SDKUtterance,
} from 'src/SdkTypes';
import { currencyKeyToCode, currencyCodeToKey } from './moneyUtils';
import * as money from 'src/gen/squareup/connect/v2/common/money';
import type Transcript from 'src/stores/objects/Transcript';
import {
  MessagesAuthenticationError,
  MessagesAuthorizationError,
} from 'src/types/Errors';

export const MESSAGE_DESTINATION_UNRECOGNIZED: MessageDestination = {
  medium: Medium.MEDIUM_UNRECOGNIZED,
};

/**
 * Contextual events are retrieved from the range of now to the oldest utterance. In the event
 * where the oldest utterance is less than the range below, we will retrieve events up to
 * this range.
 */
export const CONTEXTUAL_EVENT_MIN_RANGE = 31536000000; // 1 year, in milliseconds

/**
 * The default version of a transcript to signify one has not yet been loaded.
 */
export const UNKNOWN_TRANSCRIPT_VERSION = -1;

/**
 * Find the utterance that was spoken the earliest, from a list of utterances.
 *
 * @param {IUtterance[]} utterances The utterances, not necessarily sorted.
 */
export function earliestUtterance(utterances: IUtterance[]): IUtterance | null {
  // TODO(gabor) if we know these are sorted, this can be O(1).
  let min: number = Number.MAX_VALUE;
  let argmin: IUtterance | null = null;
  for (const utterance of utterances) {
    if (utterance.spokenAtMillis != null) {
      if (utterance.spokenAtMillis < min) {
        min = utterance.spokenAtMillis;
        argmin = utterance;
      }
    }
  }
  return argmin;
}

/**
 * Finds the most recent utterance in a list of utterances. Returns null if empty.
 *
 * @param {IUtterance[]} utterances
 * The list of utterances to search for the most recent one from.
 */
export const mostRecentUtterance = (
  utterances: IUtterance[],
): IUtterance | null => {
  if (utterances.length > 0) {
    return utterances.reduce((newestUtterance, currentUtterance) =>
      (newestUtterance.spokenAtMillis || 0) >
      (currentUtterance.spokenAtMillis || 0)
        ? newestUtterance
        : currentUtterance,
    );
  }
  return null;
};

/**
 *
 * Returns the oldest date from which contextual events should be fetched.
 *
 * @param {IUtterance[]} utterances
 * The list of utterances to search.
 * @param {boolean} isAtStartOfList
 * Flag indicating whether we are at the end of the list.
 */
export const getContextualEventsBeginTimestamp = (
  utterances: IUtterance[],
  isAtStartOfList: boolean,
): number => {
  let earliestTimestamp =
    earliestUtterance(utterances)?.spokenAtMillis ?? Date.now();
  if (
    isAtStartOfList &&
    earliestTimestamp > Date.now() - CONTEXTUAL_EVENT_MIN_RANGE
  ) {
    earliestTimestamp = Date.now() - CONTEXTUAL_EVENT_MIN_RANGE;
  }
  return earliestTimestamp;
};

/**
 * Compares 2 utterances by ID, as a proxy for utterance age. Used as an
 * argument to Array#sort, puts recent utterances first by default, unless
 * `recentLast` is set to true.
 *
 * @param {IUtterance} utterance1 - first utterance to sort by `spokenAtMillis`; by default,
 * appears before utterance2 if *newer* than utterance2
 * @param {IUtterance} utterance2 - second utterance to sort by `spokenAtMillis`; by default,
 * appears before utterance1 if *newer* than utterance1
 * @returns {number} negative return means utterance1 is newer, unless
 * `recentLast` is true
 */
export function utteranceComparator(
  utterance1: IUtterance,
  utterance2: IUtterance,
): number {
  // Set up to compare by spokenAtMillis, tie-breaking by ID
  const tie = utterance1.spokenAtMillis === utterance2.spokenAtMillis;
  const utterance1SortKey = tie ? utterance1.id : utterance1.spokenAtMillis;
  const utterance2SortKey = tie ? utterance2.id : utterance2.spokenAtMillis;

  if (!utterance1SortKey || !utterance2SortKey) {
    if (utterance1SortKey === utterance2SortKey) {
      // Case: neither one has a spokenAtMillis/id
      return 0;
    }
    return utterance1SortKey ? -1 : 1;
  }
  if (utterance1SortKey > utterance2SortKey) {
    return -1;
  } else if (utterance2SortKey > utterance1SortKey) {
    return 1;
  } else {
    return 0;
  }
}

/**
 * Compares 2 transcripts by version, as a proxy for which was updated
 * more recently. If used as an Array#sort argument, puts recently updated
 * transcripts earlier in a list.
 *
 * @param {ITranscript} transcript1 - first transcript to sort; appears before
 * transcript2 if its version is larger (more recently updated)
 * @param {ITranscript} transcript2 - second transcript to sort; appears before
 * transcript1 if its version is larger
 * @returns {number} - negative return means transcript1 was updated
 * more recently
 */
export function transcriptComparator(
  transcript1: ITranscript,
  transcript2: ITranscript,
): number {
  if (!transcript1.version || !transcript2.version) {
    if (transcript1.version === transcript2.version) {
      return 0;
    }
    return transcript1.version ? -1 : 1;
  }
  if (transcript1.version > transcript2.version) {
    return -1;
  } else if (transcript2.version > transcript1.version) {
    return 1;
  } else {
    return 0;
  }
}

/**
 * Merge two lists of utterances. Doesn't make assumptions about which page contains
 * older utterances, but older utterances should go toward the start of the list.
 *
 * @param {IUtterance[]} currentUtterances - the existing value of the utterances list
 * @param {IUtterance[]} newUtterances - the next chunk of utterances. in this case it's
 * an older list of utterances, which have already been sorted from old --> new
 * in _getUtterancesPage
 */
export function mergeUtterancesLists(
  currentUtterances: readonly IUtterance[],
  newUtterances: readonly IUtterance[],
): IUtterance[] {
  const updatedValue = currentUtterances.filter(
    (utterance) =>
      !newUtterances.some(
        (n) =>
          n.id != null &&
          (n.id === utterance.id ||
            // This is unfortunately necessary for unit tests, since IDs end up as Long's, not native
            // numbers when this is running on Node
            (typeof n.id === 'object' &&
              (n.id as Long).low === (utterance.id as unknown as Long).low)),
      ),
  );
  updatedValue.push(...(newUtterances || []));
  return updatedValue.sort((a, b) => utteranceComparator(a, b)).reverse();
}

/**
 * Finds and returns the most recent utterance preview from a list of utterance previews.
 *
 * @param {UtterancePreview[]} utterancePreviews
 * The list of utterance previews to search.
 * @returns {UtterancePreview | null}
 * Returns the most recent utterance preview from the list.
 */
export const _getMostRecentUtterancePreview = (
  utterancePreviews: UtterancePreview[],
): UtterancePreview | null => {
  // Look over the candidates and return the most recent
  let latestUtterancePreview: UtterancePreview | null = null;

  utterancePreviews.forEach((candidate: UtterancePreview) => {
    if (!latestUtterancePreview) {
      // Case: This is the only utterance we've seen, save it
      latestUtterancePreview = candidate;
    } else if (
      latestUtterancePreview.utterance.spokenAtMillis != null &&
      candidate.utterance.spokenAtMillis != null && // Note: can't use ID with local utterances
      candidate.utterance.spokenAtMillis >
        latestUtterancePreview.utterance.spokenAtMillis
    ) {
      // Case: This is the newest candidate utterance so far, save it
      latestUtterancePreview = candidate;
    }
  });

  return latestUtterancePreview;
};

/**
 * Generates an utterance preview for a transcript.
 *
 * @param {Transcript} transcript
 * The transcript to generate the transcript for.
 * @returns {UtterancePreview | null}
 * The utterance preview associated with the transcript.
 */
export const getUtterancePreviewFromTranscript = (
  transcript: Transcript,
): UtterancePreview | null => {
  const {
    customerTokens,
    previewUtterance,
    isRead,
    contactId,
    medium,
    localUtterances,
  } = transcript;

  const utterancePreviewCandidates: UtterancePreview[] = [];

  // Generate candidate preview from preview utterance in transcript
  if (Object.keys(previewUtterance).length !== 0) {
    utterancePreviewCandidates.push({
      contactId: contactId || null,
      customerTokens,
      isUnread: !isRead,
      medium: medium || Medium.MEDIUM_UNRECOGNIZED,
      utterance: previewUtterance,
    });
  }

  // Generate candidate previews from any applicable local utterances
  if (localUtterances) {
    localUtterances.forEach((localUtterance: LocalUtterance) => {
      const { utterance, photos, files } = localUtterance;
      utterancePreviewCandidates.push({
        contactId: contactId || null,
        customerTokens,
        isUnread: false,
        medium: medium || null,
        utterance,
        localPhotos: photos,
        localFiles: files,
      });
    });
  }

  return _getMostRecentUtterancePreview(utterancePreviewCandidates);
};

/**
 * Checks if a send status is considered failed. Mainly used to show red UI for
 * utterance.
 *
 * @param {Utterance.SendStatus} status
 */
export const isFailedSendStatus = (
  status: Utterance.SendStatus = Utterance.SendStatus.SEND_STATUS_UNRECOGNIZED,
): boolean => {
  switch (status) {
    case Utterance.SendStatus.PENDING:
    case Utterance.SendStatus.UPLOADING:
    case Utterance.SendStatus.SENT:
      return false;
    case Utterance.SendStatus.FAILED:
    case Utterance.SendStatus.BLOCKED:
    case Utterance.SendStatus.BLOCKED_FOR_MARKETING:
    case Utterance.SendStatus.BLOCKED_NUMBER_NOT_VERIFIED:
    default:
      return true;
  }
};

/**
 * A helper function to get the formatted preview utterance text.
 *
 * @param {UtterancePreview} utterancePreview
 */
export function getUtterancePreviewText(
  utterancePreview: UtterancePreview,
): string {
  const { utterance, localPhotos, localFiles } = utterancePreview;

  const hasCoupon = utterance.metadata?.coupon || false;
  const hasCheckoutLink = utterance.metadata?.checkoutLink || false;
  const hasSquareGoReview = utterance.metadata?.squareGoReview || false;
  const hasPhotos =
    (utterance?.attachments &&
      utterance?.attachments.length > 0 &&
      utterance.attachments[0].type === Attachment.AttachmentType.IMAGE) ||
    (localPhotos && localPhotos.length > 0);
  const hasFiles =
    (utterance?.attachments &&
      utterance?.attachments.length > 0 &&
      utterance.attachments[0].type === Attachment.AttachmentType.FILE) ||
    (localFiles && localFiles.length > 0);
  const hasVoicemail = utterance.metadata?.voicemail || false;
  const hasMissedCall = utterance.metadata?.inboundCall || false;
  const hasMessage = utterance.plainText !== '';

  let snippet = utterance.plainText ?? '';

  if (hasSquareGoReview) {
    // Placing above the hasPhotos conditional because Square Go reviews may have photos
    const { message, stars } = utterance.metadata?.squareGoReview || {};

    if (message && message.length > 0) {
      snippet = t(
        'TranscriptsListItem.utterance_preview.squareGoReviewHasMessage',
        {
          stars,
          message,
        },
      );
    } else if (hasPhotos) {
      snippet = t(
        'TranscriptsListItem.utterance_preview.squareGoReviewNoMessageHasPhotos',
        {
          stars,
          photoCount: utterance?.attachments?.length ?? 0,
        },
      );
    } else {
      snippet = t(
        'TranscriptsListItem.utterance_preview.squareGoReviewNoMessageNoPhotos',
        {
          stars,
        },
      );
    }
  } else if (
    hasCoupon ||
    hasCheckoutLink ||
    (hasPhotos && !hasMessage) ||
    (hasFiles && !hasMessage)
  ) {
    // Case: contextual event

    // Determine text for event
    let eventText = '';
    if (hasCoupon) {
      eventText = t('TranscriptsListItem.utterance_preview.coupon');
    } else if (hasCheckoutLink) {
      eventText = t('TranscriptsListItem.utterance_preview.paymentLink');
    } else if (hasPhotos) {
      eventText = t('TranscriptsListItem.utterance_preview.photo', {
        count: utterance?.attachments?.length || localPhotos?.length || 0,
      });
    } else if (hasFiles) {
      eventText = t('TranscriptsListItem.utterance_preview.file', {
        count: utterance?.attachments?.length || localFiles?.length || 0,
      });
    }

    // Prepend "You sent ____" if speaker type is merchant, else "Sent ____"
    if (utterance.speakerType === Utterance.SpeakerType.MERCHANT) {
      snippet = t('TranscriptsListItem.utterance_preview.merchantSent', {
        item: eventText,
      });
    } else {
      snippet = t('TranscriptsListItem.utterance_preview.customerSent', {
        item: eventText,
      });
    }
  } else if (hasVoicemail) {
    // Only customers can send voicemail, so no need to prepend for merchants.
    snippet = utterance.metadata?.voicemail?.transcription
      ? t('TranscriptsListItem.utterance_preview.voicemailWithTranscription', {
          transcription: utterance.metadata.voicemail.transcription,
        })
      : t('TranscriptsListItem.utterance_preview.voicemailDefault');
  } else if (hasMissedCall) {
    snippet = t('ContextualEvent.missedCall.title');
  } else {
    // Case: normal utterance

    // Set [Empty Message] if there is no snippet
    if (snippet === '') {
      snippet = t('TranscriptsListItem.empty_message');
    }

    // Prepend "You:" if speaker type is merchant
    if (utterance.speakerType === Utterance.SpeakerType.MERCHANT) {
      snippet = t('TranscriptsListItem.utterance_preview.you', {
        snippet,
      });
    }
  }

  return snippet;
}

/**
 * Compares two transcript. Used as an argument to Array.sort()
 * The transcripts are compared based on the preview utterance.
 *
 * @param {Transcript} transcriptA
 * The first transcript; smaller return value means this
 * transcript should appear before convB
 * @param {Transcript} transcriptB
 * The second transcript to compare against
 * @returns {number}
 */
export const compareTranscripts = (
  transcriptA: Transcript,
  transcriptB: Transcript,
): number => {
  const keyA = getUtterancePreviewFromTranscript(transcriptA);
  const keyB = getUtterancePreviewFromTranscript(transcriptB);

  if (keyA == null && keyB == null) {
    return 0;
  } else if (keyA == null || keyB == null) {
    return keyA == null ? 1 : -1;
  } else if (keyA.utterance == null || keyB.utterance == null) {
    if (keyA.utterance == null && keyB.utterance == null) {
      return 0;
    }
    return keyA.utterance == null ? 1 : -1;
  }

  return utteranceComparator(keyA.utterance, keyB.utterance);
};

/**
 * Get the text label that represent a specific medium.
 *
 * @param {Medium | ContextualEventType} medium
 */
export function mediumToString(medium: Medium | ContextualEventType): string {
  switch (medium) {
    case Medium.SMS:
      return t('common.medium.sms');
    case Medium.EMAIL:
      return t('common.medium.email');
    case 'APPOINTMENT':
      return t('ContextualEvent.type.appointments');
    case 'FEEDBACK':
      return t('ContextualEvent.type.feedback');
    case 'RECEIPT':
      return t('ContextualEvent.type.receipt');
    case 'MARKETING':
      return t('ContextualEvent.type.marketing');
    case 'FORM_SUBMISSION':
      return t('ContextualEvent.type.form');
    default:
      return t('common.medium.unknown');
  }
}

/**
 * Get the dialogue conversation token from a transcript view item, if present.
 * It checks the item dataType if it is 'FEEDBACK', 'RECEIPT', or 'MARKETING'. These
 * data types have a common property called conversationToken which is returned.
 *
 * @param {TranscriptViewItem} item
 * @returns {string} the token if present
 */
export function getDialogueConversationToken(
  item: TranscriptViewItem,
): string | undefined {
  if (
    item.dataType === 'FEEDBACK' ||
    item.dataType === 'RECEIPT' ||
    item.dataType === 'MARKETING'
  ) {
    const data = item.data as Feedback | Receipt | Marketing;
    return data.conversationToken;
  }

  return undefined;
}

/**
 * A function to determine if a consent status is granted to
 * the fullest, i.e. merchant can send any kind of message including
 * marketing and coupons.
 *
 * @param {ConsentStatus} status
 */
export function hasFullGrantedConsent(status: ConsentStatus): boolean {
  return (
    status === ConsentStatus.GRANTED_MARKETING ||
    status === ConsentStatus.GRANTED_BY_DEFAULT
  );
}

/**
 * Determine if a consent status allows the user to type a message.
 * This is very simialr to hasAnyGrantedConsent, but
 * it also covers DENIED_BY_DEFAULT which allows typing in the input bar
 * but prevents sending until the user confirm consent.
 *
 * @param {ConsentStatus} status
 */
export function hasConsentToTypeMessage(status: ConsentStatus): boolean {
  return (
    status === ConsentStatus.GRANTED_MARKETING ||
    status === ConsentStatus.GRANTED_BY_DEFAULT ||
    status === ConsentStatus.GRANTED_TRANSACTIONAL ||
    status === ConsentStatus.DENIED_BY_DEFAULT
  );
}

/**
 * Create the client id for utterance by concatenating the transcriptId
 * with a randomly generated id. Used as the key to IndexedDB for local
 * utterances, and used to check that an utterance is sent and commited
 * to the database.
 *
 * @param {number} transcriptId
 * @returns {string} The client id generated
 */
export const createUtteranceClientId = (transcriptId: number): string =>
  JSON.stringify({
    transcriptId: `${transcriptId}`,
    uuid: uuidv4(),
  } as LocalUtteranceClientId);

/**
 * A helper function to convert a proto Transcript into the format
 * that the Messages SDK expects, which for now is a preview utterance,
 * with string values for the SpeakerType and Medium enums.
 *
 * @param {ITranscript} transcript - the transcript we are converting
 * and sending to the SDK
 * @returns {SDKConversation} - utterances are empty if there was no preview
 * utterance on this transcript.
 */
export function protoTranscriptToSDKConversation(
  transcript: ITranscript,
): SDKConversation {
  if (!transcript?.details?.previewUtterance) {
    return {
      utterances: [] as SDKUtterance[],
    };
  }
  const previewUtterance = transcript.details.previewUtterance;
  const mediumProto = transcript.contactMethodAndSellerKey?.medium;
  const transcriptId = transcript.id;
  const {
    id,
    spokenAtMillis,
    speakerType: speakerTypeProto,
    plainText,
  } = previewUtterance;
  let speakerType;
  switch (speakerTypeProto) {
    case Utterance.SpeakerType.CUSTOMER:
      speakerType = 'CUSTOMER';
      break;
    case Utterance.SpeakerType.MERCHANT:
      speakerType = 'MERCHANT';
      break;
    case Utterance.SpeakerType.BOT:
      speakerType = 'BOT';
      break;
    case Utterance.SpeakerType.SERVICE:
      speakerType = 'SERVICE';
      break;
    default:
      Logger.warn(
        `Utterance missing speakerType ${JSON.stringify(previewUtterance)}`,
      );
      speakerType = 'CUSTOMER';
  }
  let medium;
  switch (mediumProto) {
    case Medium.SMS:
      medium = 'SMS';
      break;
    case Medium.EMAIL:
      medium = 'EMAIL';
      break;
    default:
      Logger.warn(
        `Utterance missing medium ${JSON.stringify(previewUtterance)}`,
      );
  }
  return {
    utterances: [
      {
        id: id || 0,
        transcriptId: transcriptId || 0,
        spokenAtMillis: spokenAtMillis || 0,
        speakerType,
        medium,
        text: plainText || '',
        richText: plainText || '',
      },
    ] as SDKUtterance[],
  };
}

/**
 * Helper function to get the formatted amount for a checkout link.
 *
 * @param {Money} amount - Money amount to format
 * @returns {money.IMoney | undefined} - Formatted money amount
 */
export const _getCheckoutLinkAmount = (
  amount: Money | undefined,
): money.IMoney | undefined => {
  // Zero is also considered invalid (should return undefined instead)
  if (amount?.amount && amount?.currency) {
    return {
      amount: amount.amount,
      currency: currencyKeyToCode(amount.currency),
    };
  }

  if (amount?.amount && !amount?.currency) {
    Logger.logWithSentry(
      'Checkout link amount is set but currency is missing',
      'warning',
    );
  }

  return undefined;
};

/**
 * Converts a checkout link from graphQL type to utterance metadata type.
 *
 * @param {GraphQLCheckoutLinkResponse} graphQLCheckoutLink
 * @returns {CheckoutLink}
 */
export function checkoutLinkGraphQLToProto(
  graphQLCheckoutLink: GraphQLCheckoutLinkResponse,
): CheckoutLink {
  let type: Utterance.Metadata.CheckoutLink.CheckoutLinkType =
    Utterance.Metadata.CheckoutLink.CheckoutLinkType.ONE_TIME_LINK;
  switch (graphQLCheckoutLink.type) {
    case 'ITEM_LINK':
      type = Utterance.Metadata.CheckoutLink.CheckoutLinkType.ITEM_LINK;
      break;
    case 'PAYMENT_LINK':
      type = Utterance.Metadata.CheckoutLink.CheckoutLinkType.PAYMENT_LINK;
      break;
    case 'DONATION_LINK':
      type = Utterance.Metadata.CheckoutLink.CheckoutLinkType.DONATION_LINK;
      break;
    case 'ONE_TIME_LINK':
      type = Utterance.Metadata.CheckoutLink.CheckoutLinkType.ONE_TIME_LINK;
      break;
    default:
      break;
  }

  return {
    ...graphQLCheckoutLink,
    amount: _getCheckoutLinkAmount(graphQLCheckoutLink.amount),
    type,
  };
}

/**
 * Get the transcript view item for an utterance with feedback metadata.
 *
 * @param {Utterance.Metadata.IFeedback} feedback The feedback metadata
 * @param {LocalUtterance} localUtterance The local utterance
 * @param {string | undefined} unitToken The unit token.
 */
export const getTranscriptViewItemForFeedbackMetadata = (
  feedback: Utterance.Metadata.IFeedback,
  localUtterance: LocalUtterance,
  unitToken?: string,
): TranscriptViewItem => {
  // Feedback metadata can be displayed as different cards depending on the context
  if (feedback.sentiment === 'POSITIVE' || feedback.sentiment === 'NEGATIVE') {
    /**
     * Extract feedback
     *
     * Anything dialogue that has a POSITIVE or NEGATIVE sentiment is
     * considered a feedback.
     */
    const data = {
      conversationToken: feedback.token,
      createdAt: feedback.createdAtMillis,
      comment: feedback.feedbackComment,
      sentiment: feedback.sentiment,
      facets: feedback.facets as FeedbackFacet[],
      paymentToken: feedback.paymentToken,
      payment: {
        currencyCode: feedback.paymentAmount?.currency
          ? currencyCodeToKey(feedback.paymentAmount?.currency)
          : undefined,
        amount: feedback.paymentAmount?.amount,
      },
    };
    return {
      timestampMillis: localUtterance.utterance.spokenAtMillis ?? Date.now(),
      unitToken,
      dataType: 'FEEDBACK',
      componentType: 'CUSTOMER_EVENT_CARD',
      data,
      attachedUtterance: localUtterance,
    };
  } else if (feedback.channel === 'RECEIPTS') {
    /**
     * Extract receipt
     *
     * Anything dialogue that has the channel as RECEIPTS is considered
     * a reply to receipt.
     */
    const data = {
      conversationToken: feedback.token,
      createdAt: feedback.createdAtMillis,
      comment: feedback.feedbackComment,
      paymentToken: feedback.paymentToken,
      payment: {
        currencyCode: feedback.paymentAmount?.currency
          ? currencyCodeToKey(feedback.paymentAmount?.currency)
          : undefined,
        amount: feedback.paymentAmount?.amount,
      },
    };
    return {
      timestampMillis: localUtterance.utterance.spokenAtMillis ?? Date.now(),
      unitToken,
      dataType: 'RECEIPT',
      componentType: 'CUSTOMER_EVENT_CARD',
      data,
      attachedUtterance: localUtterance,
    };
  } else if (feedback.channel === 'OUTREACH') {
    /**
     * 4. Extract marketing
     *
     * Anything dialogue that has
     */
    const data = {
      conversationToken: feedback.token,
      createdAt: feedback.createdAtMillis,
      comment: feedback.feedbackComment,
      url: feedback.marketingSourceUrl,
      name: feedback.marketingName,
    };
    return {
      timestampMillis: localUtterance.utterance.spokenAtMillis ?? Date.now(),
      unitToken,
      dataType: 'MARKETING',
      componentType: 'CUSTOMER_EVENT_CARD',
      data,
      attachedUtterance: localUtterance,
    };
  } else {
    return {
      timestampMillis: localUtterance.utterance.spokenAtMillis ?? Date.now(),
      unitToken,
      dataType: 'UTTERANCE',
      componentType: 'UTTERANCE_CARD',
      data: localUtterance,
      attachedUtterance: localUtterance,
    };
  }
};

/**
 * Transform a local utterance into a transcript view item by looking at the
 * metadata of the utterance.
 *
 * @param {LocalUtterance} localUtterance
 * @param {string} unitToken
 */
export function localUtteranceToTranscriptViewItem(
  localUtterance: LocalUtterance,
  unitToken?: string,
): TranscriptViewItem {
  let item: TranscriptViewItem;
  if (localUtterance.utterance.metadata?.coupon) {
    // If coupon metadata is present, show it as a coupon event card
    item = {
      timestampMillis: localUtterance.utterance.spokenAtMillis ?? Date.now(),
      unitToken,
      dataType: 'COUPON',
      componentType: 'MERCHANT_EVENT_CARD',
      data: localUtterance.utterance.metadata.coupon,
      attachedUtterance: localUtterance,
    };
  } else if (localUtterance.utterance.metadata?.order) {
    // If order metadata is present, show it as a order event card
    item = {
      timestampMillis: localUtterance.utterance.spokenAtMillis ?? Date.now(),
      unitToken,
      dataType: 'ORDER',
      componentType: 'MERCHANT_EVENT_CARD',
      data: localUtterance.utterance.metadata.order,
      attachedUtterance: localUtterance,
    };
  } else if (localUtterance.utterance.metadata?.checkoutLink) {
    // If checkout link metadata is present, show it as a checkout link event card
    item = {
      timestampMillis: localUtterance.utterance.spokenAtMillis ?? Date.now(),
      unitToken,
      dataType: 'CHECKOUT_LINK',
      componentType: 'MERCHANT_EVENT_CARD',
      data: localUtterance.utterance.metadata.checkoutLink,
      attachedUtterance: localUtterance,
    };
  } else if (localUtterance.utterance.metadata?.invoice) {
    // If invoice metadata is present, show it as an invoice event card
    item = {
      timestampMillis: localUtterance.utterance.spokenAtMillis ?? Date.now(),
      unitToken,
      dataType: 'INVOICE',
      componentType: 'MERCHANT_EVENT_CARD',
      data: localUtterance.utterance.metadata.invoice,
      attachedUtterance: localUtterance,
    };
  } else if (localUtterance.utterance.metadata?.estimate) {
    // If estimate metadata is present, show it as an estimate event card
    item = {
      timestampMillis: localUtterance.utterance.spokenAtMillis ?? Date.now(),
      unitToken,
      dataType: 'ESTIMATE',
      componentType: 'MERCHANT_EVENT_CARD',
      data: localUtterance.utterance.metadata.estimate,
      attachedUtterance: localUtterance,
    };
  } else if (localUtterance.utterance.metadata?.ecomFormEntry) {
    // If a form entry is present, show a FORM_SUBMISSION CustomerEventCard
    item = {
      timestampMillis: localUtterance.utterance.spokenAtMillis ?? Date.now(),
      unitToken,
      dataType: 'FORM_SUBMISSION',
      componentType: 'CUSTOMER_EVENT_CARD',
      data: localUtterance.utterance.metadata.ecomFormEntry,
      attachedUtterance: localUtterance,
    };
  } else if (localUtterance.utterance.metadata?.messagesPluginEntry) {
    item = {
      timestampMillis: localUtterance.utterance.spokenAtMillis ?? Date.now(),
      unitToken,
      dataType: 'MESSAGES_PLUGIN_SUBMISSION',
      componentType: 'CUSTOMER_EVENT_CARD',
      data: localUtterance.utterance.metadata.messagesPluginEntry,
      attachedUtterance: localUtterance,
    };
  } else if (localUtterance.utterance.metadata?.voicemail) {
    item = {
      timestampMillis: localUtterance.utterance.spokenAtMillis ?? Date.now(),
      unitToken,
      dataType: 'VOICEMAIL',
      componentType: 'CUSTOMER_EVENT_CARD',
      data: localUtterance.utterance.metadata.voicemail,
      attachedUtterance: localUtterance,
    };
  } else if (localUtterance.utterance.metadata?.feedback) {
    item = getTranscriptViewItemForFeedbackMetadata(
      localUtterance.utterance.metadata?.feedback,
      localUtterance,
      unitToken,
    );
  } else if (localUtterance.utterance.metadata?.squareGoReview) {
    // If Square Go metadata is present, show a Square Go Card
    item = {
      timestampMillis: localUtterance.utterance.spokenAtMillis ?? Date.now(),
      unitToken,
      dataType: 'SQUARE_GO_REVIEW',
      componentType: 'CUSTOMER_EVENT_CARD',
      data: localUtterance.utterance.metadata.squareGoReview,
      attachedUtterance: localUtterance,
    };
  } else if (localUtterance.utterance.metadata?.inboundCall) {
    item = {
      timestampMillis: localUtterance.utterance.spokenAtMillis ?? Date.now(),
      unitToken,
      dataType: 'INBOUND_CALL',
      componentType: 'CUSTOMER_EVENT_CARD',
      data: localUtterance.utterance.metadata.inboundCall,
      attachedUtterance: localUtterance,
    };
  } else {
    // Construct the item, note that dataType and componentType MUST
    // both be 'UTTERANCE' for it to render properly.
    item = {
      timestampMillis: localUtterance.utterance.spokenAtMillis ?? Date.now(),
      unitToken,
      dataType: 'UTTERANCE',
      componentType: 'UTTERANCE_CARD',
      data: localUtterance,
    };
  }

  return item;
}

export const sdkMediumToMedium: Record<SdkMedium, Medium> = {
  SMS: Medium.SMS,
  EMAIL: Medium.EMAIL,
};

export const mediumToEventNameString: Record<Medium, string> = {
  [Medium.SMS]: 'sms',
  [Medium.EMAIL]: 'email',
  [Medium.FACEBOOK]: 'unknown',
  [Medium.MEDIUM_UNRECOGNIZED]: 'unknown',
};

/**
 * Create a medium timestamp TranscriptViewItem object from a
 * TranscriptViewItem.
 *
 * @param {TranscriptViewItem} item - the item that we want to create the timestamp for
 * @param {Medium} medium - the medium associated with the transcript of the item
 * @returns {TranscriptViewItem} the timestamp in the form of a TranscriptViewItem
 */
export function createMediumTimestampItem(
  item: TranscriptViewItem,
  medium: Medium,
): TranscriptViewItem {
  const data: MediumTimestamp = {};

  // Extract medium
  if (item.dataType === 'APPOINTMENT') {
    data.medium = 'APPOINTMENT';
  } else {
    data.medium = medium;
  }

  // Construct the item, note that dataType and componentType MUST
  // both be 'TIMESTAMP'
  return {
    timestampMillis: item.timestampMillis,
    unitToken: item.unitToken,
    dataType: 'TIMESTAMP',
    componentType: 'TIMESTAMP',
    data,
  };
}

/**
 * Compares 2 view items and determine if a new medium timestamp
 * is required to be shown in between them. These are the scenarios:
 * - Change in dataType when current item has no utterance
 * - Change in date
 * - Appointment cards should always have a timestamp
 *
 * Examples when timestamp would appear between:
 * 1) Appointment Event (Jan 1)
 *    <timestamp>
 *    Feedback with message (Jan 1)
 *
 * 2) Utterance (Jan 1)
 *    <timestamp>
 *    Utterance on (Jan 2)
 *
 * 3) Utterance (Jan 1, 3:00pm)
 *    <timestamp>
 *    Appointment Event (Jan 1, 3:00pm)
 *
 * Examples when timestamp would not appear between:
 * 1) Feedback with message (Jan 1)
 *    Utterance (Jan 1)
 *
 * 2) Coupon (Jan 1)
 *    Feedback with message (Jan 1)
 *
 * @param {TranscriptViewItem} current
 * @param {TranscriptViewItem} previous
 */
export function needsMediumTimestamp(
  current: TranscriptViewItem,
  previous: TranscriptViewItem,
): boolean {
  let utterance: LocalUtterance | undefined;
  if (current.dataType === 'UTTERANCE') {
    utterance = current.data as LocalUtterance;
  } else if (current.attachedUtterance) {
    utterance = current.attachedUtterance;
  }

  const itemDate = new Date(current.timestampMillis);
  const previousItemDate = new Date(previous.timestampMillis);
  // Ignore the time part of the dates
  itemDate.setHours(0, 0, 0, 0);
  previousItemDate.setHours(0, 0, 0, 0);

  return (
    (current.dataType !== previous.dataType && !utterance) ||
    itemDate.getTime() - previousItemDate.getTime() > 0 ||
    current.dataType === 'APPOINTMENT'
  );
}

/**
 * Extract the utterance from a view item, if any.
 *
 * @param {TranscriptViewItem} viewItem
 */
export const getUtteranceFromTranscriptViewItem = (
  viewItem: TranscriptViewItem,
): IUtterance | null => {
  switch (viewItem.dataType) {
    case 'UTTERANCE':
      return (viewItem.data as LocalUtterance).utterance;
    case 'FORM_SUBMISSION':
    case 'MESSAGES_PLUGIN_SUBMISSION':
    case 'RECEIPT':
    case 'MARKETING':
    case 'FEEDBACK':
    case 'SQUARE_GO_REVIEW':
    case 'INBOUND_CALL':
      if (viewItem.attachedUtterance && viewItem.attachedUtterance.utterance) {
        return viewItem.attachedUtterance.utterance;
      }
      return null;
    default:
      return null;
  }
};

/**
 * Retrieves a set of the existing client IDs found in a list of utterances returned from the
 * server. Commonly used to de-dupe local utterances from the list of utterances returned by the
 * server.
 *
 * @param {IUtterance[]} utterances
 * The list of utterances to search for client Ids from.
 * @returns {Set<string>}
 * The set of existing client Ids found in the provided list of utterances.
 */
export const getExistingClientIds = (utterances: IUtterance[]): Set<string> => {
  const serverSideClientIds = new Set<string>();
  utterances.forEach((utterance) => {
    if (utterance.metadata?.clientId) {
      serverSideClientIds.add(utterance.metadata.clientId);
    }
  });
  return serverSideClientIds;
};

/**
 * Helper method to mark a local utterance as failed.
 *
 * @param {Transcript} transcript
 * The transcript the local utterance belongs to.
 * @param {LocalUtterance} localUtterance
 * The local utterance to mark as failed.
 * @param {Utterance.SendStatus} sendStatus
 * A status different than 'failed' to mark the local utterance as instead.
 */
export const failLocalUtterance = async (
  transcript: Transcript,
  localUtterance: LocalUtterance,
  sendStatus?: Utterance.SendStatus,
): Promise<void> => {
  const { utterance, photos } = localUtterance;
  if (
    photos &&
    photos.length > 0 &&
    !photos.every((photo) => photo.url.startsWith('https://media.tenor.com/'))
  ) {
    // If this utterance contains local files, the urls will be broken once the browser cleans
    // its memory. So instead of keeping the utterance with a failed status, we remove it.
    await transcript.removeLocalUtterance(
      utterance.metadata?.clientId as string,
    );
    return;
  }
  await transcript.updateLocalUtterance({
    ...localUtterance,
    utterance: {
      ...utterance,
      sendStatus: sendStatus || Utterance.SendStatus.FAILED,
    },
  });
};

/**
 * Definition of a generic response from Messenger RPCs used in error handling
 */
type ResponseError = {
  status: {
    code: Status.Code | AuxiliaryStatus.Code;
  };
};

/**
 * Determines if an error is due to an unauthorized or unauthenticated status code.
 * Used to filter logs to Sentry, which can cause excessive noise.
 *
 * @param {unknown} error
 * The error to check if it is due to an unauthorized/unauthenticated status code.
 * @param {boolean} isMessengerAuxiliaryService
 * Whether this is for a call to the MessengerAuxiliaryService which has its own
 * status codes.
 * @returns {boolean}
 * Flag indicating if this is an auth error.
 */
export const isAuthError = (
  error: unknown,
  isMessengerAuxiliaryService = false,
): boolean => {
  if (
    error instanceof MessagesAuthenticationError ||
    error instanceof MessagesAuthorizationError
  ) {
    return true;
  }
  const code = (error as ResponseError)?.status?.code;
  if (isMessengerAuxiliaryService) {
    return (
      code === AuxiliaryStatus.Code.UNAUTHORIZED ||
      code === AuxiliaryStatus.Code.FORBIDDEN
    );
  }
  return code === Status.Code.UNAUTHORIZED || code === Status.Code.FORBIDDEN;
};

/**
 * Basic helper to determine if two arrays are shallowly equal.
 *
 * @template Type
 * @param {Type[]} one
 * The original array of values.
 * @param {Type[]} two
 * The second array to check if it is shallowly equal to the original.
 * @returns {boolean}
 * Flag indicating if the two arrays are shallowly equal.
 */
export const isShallowEqual = <Type>(one: Type[], two: Type[]): boolean =>
  one.length === two.length &&
  one.every((value, index) => value === two[index]);

/**
 * Returns the primary contact method for a given medium for a customer.
 *
 * @param {Contact} contact
 * The customer data to read the primary contact method from.
 * @param {Medium} medium
 * The medium to find the primary contact for.
 */
export const getPrimaryContactMethod = (
  contact: Contact,
  medium: Medium,
): Contact.ContactMethod | undefined =>
  contact.contactMethods.find(
    (contactMethod) =>
      contactMethod.medium === medium && contactMethod.isPrimary,
  );

/**
 * Returns the primary display contact (i.e. formatted email or phone number)
 * for a given medium for a customer.
 *
 * @param {Contact} contact
 * The customer data to read the primary display contact from.
 * @param {Medium} medium
 * The medium to find the primary display contact for.
 */
export const getPrimaryDisplayContact = (
  contact: Contact,
  medium: Medium,
): string | undefined =>
  getPrimaryContactMethod(contact, medium)?.displayContact;

/**
 * Returns the correct referral page for the 'Click CBD Learn More' CDP event.
 *
 * @param {object} args
 * @param {boolean} args.isTranscriptView
 * Flag indicating whether the transcript view is currently shown.
 * @param {boolean} args.isEmpty
 * Flag indicating if the transcript view is empty.
 * @param {boolean} args.isActive
 * Flag indicating if the transcript is active.
 */
export const getReferralPageName = ({
  isTranscriptView,
  isEmpty,
  isActive,
}: {
  isTranscriptView: boolean;
  isEmpty: boolean;
  isActive: boolean;
}): string => {
  if (isTranscriptView) {
    if (isEmpty) {
      return 'conversation_compose';
    } else if (isActive) {
      return 'conversation_active';
    } else {
      return 'conversation_assistant';
    }
  }
  return 'new_messages_user';
};
