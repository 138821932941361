import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import Link from 'src/components/Link/Link';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { MarketButton, MarketPill, MarketRow } from 'src/components/Market';
import { amountToCurrencyString } from 'src/utils/moneyUtils';
import { useLongDateFromMicroseconds } from 'src/utils/timeUtils';
import { getDashboardPricingAndSubscriptionsUrl } from 'src/utils/url';
import './SubscriptionSetting.scss';

/**
 * Component that renders the subscription section of the settings page.
 */
const SubscriptionSetting = observer((): ReactElement => {
  const { navigation, user, subscription, event } =
    useMessengerControllerContext();
  const { t } = useTranslation();
  const freeTrialExpiryDate = useLongDateFromMicroseconds(
    subscription.freeTrialEndAt || 0,
  );
  const subscriptionExpiryDate = useLongDateFromMicroseconds(
    subscription.endAt || 0,
  );

  if (!subscription.isSubscribed) {
    return (
      <>
        <h3>{t('SettingsPage.subscription.title')}</h3>
        <p className="paragraph-30">
          {subscription.hasPreviouslySubscribed ? (
            <Trans i18nKey="SettingsPage.subscription.free.upgrade_desc_prev_sub">
              {"You're using the free version of Messages. "}
              <Link
                onClick={() => {
                  event.track('Click Messages Plus Subscribe', {
                    referral_page_name: 'messages_settings',
                    free_trial_eligible: false,
                  });
                  navigation.sheet.navigateTo('MESSAGES_PLUS_PRICING');
                }}
              >
                Subscribe to Messages Plus
              </Link>
              .
            </Trans>
          ) : (
            <Trans i18nKey="SettingsPage.subscription.free.upgrade_desc">
              {"You're using the free version of Messages. "}
              <Link
                onClick={() => {
                  event.track('Click Messages Plus Subscribe', {
                    referral_page_name: 'messages_settings',
                    free_trial_eligible: true,
                  });
                  navigation.sheet.navigateTo('MESSAGES_PLUS_PRICING');
                }}
              >
                Try Messages Plus free for 30 days
              </Link>
              .
            </Trans>
          )}
        </p>
      </>
    );
  }

  return (
    <>
      <h3 className="Subscription__title">
        {t('SettingsPage.subscription.title')}
      </h3>
      <MarketRow>
        <label slot="label">
          {t('SettingsPage.subscription.messages_plus_title')}
          {subscription.isSubscriptionInGrace && (
            <MarketPill
              className="Subscription__payment-failed"
              variant="critical"
            >
              {t('SettingsPage.subscription.payment_failed')}
            </MarketPill>
          )}
        </label>
        <p slot="subtext">
          {t('SettingsPage.subscription.cost_per_location', {
            cost: amountToCurrencyString(
              subscription.pricing?.amount || 0,
              user.currencyCode,
              user.locale,
            ),
          })}
        </p>
        {!subscription.isExpiring && (
          <p slot="subtext">
            {t('SettingsPage.subscription.location_count_label', {
              numLocations: subscription.unitUsage,
            })}
          </p>
        )}
        {subscription.isFreeTrial ? (
          <>
            <label slot="side-label" className="medium-30">
              {t('SettingsPage.subscription.free_trial')}
            </label>
            <label
              slot="side-label"
              className={
                subscription.isFreeTrialExpiring
                  ? 'SubscriptionSetting__expiry-text'
                  : 'paragraph-20'
              }
            >
              {t('SettingsPage.subscription.free_trial_expiring', {
                date: freeTrialExpiryDate,
              })}
            </label>
          </>
        ) : subscription.isSubscriptionExpiring ? (
          <label slot="side-label" className="SubscriptionSetting__expiry-text">
            {t('SettingsPage.subscription.service_expiring', {
              date: subscriptionExpiryDate,
            })}
          </label>
        ) : (
          <label slot="side-label">
            {t('SettingsPage.subscription.total_cost', {
              cost: amountToCurrencyString(
                (subscription.pricing?.amount || 0) * subscription.unitUsage,
                user.currencyCode,
                user.locale,
              ),
            })}
          </label>
        )}
      </MarketRow>
      <MarketButton
        href={getDashboardPricingAndSubscriptionsUrl}
        target="_blank"
        onClick={() => {
          event.track('Click Manage Subscription', {
            referral_page_name: 'messages_settings',
          });
        }}
      >
        {t('SettingsPage.subscription.manage')}
      </MarketButton>
    </>
  );
});

export default SubscriptionSetting;
