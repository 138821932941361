import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import { MarketBanner } from 'src/components/Market';
import { getDashboardPricingAndSubscriptionsUrl } from 'src/utils/url';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { useLongDateFromMicroseconds } from 'src/utils/timeUtils';
import 'src/components/MessagesPlusExpiringBanner/MessagesPlusExpiringBanner.scss';

/**
 * Banner that is displayed when a free trial is either:
 * 1. Canceled, meaning it will not continue into an active paid subscription,
 * or
 * 2. A subset of units are removed from the free trial, but the free trial is still active
 */
const FreeTrialExpiringBanner = observer((): ReactElement => {
  const { t } = useTranslation();
  const { tooltip, subscription } = useMessengerControllerContext();
  const freeTrialEndDate = useLongDateFromMicroseconds(
    subscription.freeTrialEndAt,
  );

  return (
    <MarketBanner
      variant="info"
      dismissable
      className="FreeTrialExpiringBanner"
      onMarketBannerDismissed={() => {
        tooltip.dismiss('FREE_TRIAL_EXPIRING_BANNER');
      }}
      data-testid="FreeTrialExpiringBanner"
    >
      <Trans i18nKey="SubscriptionExpiringBanner.free_trial.text">
        {'Your Messages Plus free trial will end on '}
        <span className="MessagesPlusExpiringBanner__expiry-date">
          {{ date: freeTrialEndDate }}
        </span>
        .
      </Trans>
      <a
        href={getDashboardPricingAndSubscriptionsUrl}
        target="_self"
        slot="action"
      >
        {t('SubscriptionExpiringBanner.button_text')}
      </a>
    </MarketBanner>
  );
});

export default FreeTrialExpiringBanner;
