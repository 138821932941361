import React, {
  ChangeEvent,
  ReactElement,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  MarketBanner,
  MarketButton,
  MarketContentCard,
  MarketDivider,
  MarketInlineStatus,
  MarketInputText,
  MarketTextarea,
} from 'src/components/Market';
import {
  Attachment,
  ConsentStatus,
  Medium,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import SendIcon from 'src/svgs/SendIcon';
import SendCouponModal from 'src/pages/TranscriptViewPage/components/SendCouponModal/SendCouponModal';
import { LocalFile } from 'src/MessengerTypes';
import ConfirmConsentModal from 'src/pages/TranscriptViewPage/components/ConfirmConsentModal/ConfirmConsentModal';
import CheckoutLinkIcon from 'src/svgs/CheckoutLinkIcon';
import CheckoutLinkModal from 'src/pages/TranscriptViewPage/components/CheckoutLinkModal/CheckoutLinkModal';
import { getMessageInputMediumLabel } from './utils';
import InputMenu from './components/InputMenu/InputMenu';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import BlockedBanner from 'src/pages/TranscriptViewPage/components/BlockedBanner/BlockedBanner';
import { MarketTextareaValueChangeEvent } from 'src/MarketTypes';
import { getReactRoot } from 'src/utils/shadowDomUtils';
import classNames from 'classnames';
import { KEY_MESSAGES_PLUS } from 'src/stores/FeatureFlagStore';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import './MessageInput.scss';
import VerificationInProgressBanner from 'src/components/VerificationInProgressBanner/VerificationInProgressBanner';
import { createUtteranceClientId } from 'src/utils/transcriptUtils';
import VoicemailCustomizationLaunchBanner from 'src/pages/TranscriptViewPage/components/VoicemailCustomizationLaunchBanner/VoicemailCustomizationLaunchBanner';
import AttachmentsReel from './components/AttachmentsReel/AttachmentsReel';
import FileLimitReachedDialog from './components/FileLimitReachedDialog/FileLimitReachedDialog';
import { getTotalFileSize } from 'src/utils/fileUtils';
import VerificationFailedRetryableBanner from 'src/pages/TranscriptViewPage/components/VerificationFailedRetryableBanner/VerificationFailedRetryableBanner';
import IneligibleBanner from 'src/pages/TranscriptsListPage/components/IneligibleBanner/IneligibleBanner';
import UpgradeBanner from './components/UpgradeBanner/UpgradeBanner';
import InactiveBanner from './components/InactiveBanner/InactiveBanner';

// The height of a single row in the message input field, in pixels.
export const TEXTAREA_ROW_HEIGHT = 24;

// The max height of the photo reel inside the input bar, in pixels.
const MAX_PHOTO_REEL_HEIGHT = 108;

// The max number of rows to display in the text area.
const MAX_ROWS = 10;

// Character limit as recommended by https://datatracker.ietf.org/doc/html/rfc5322#section-2.1.1
const MAX_EMAIL_SUBJECT_CHAR_LIMIT = 78;

/**
 * Takes height of some rendered text and returns the number of rows
 * the overlaid textarea should take up to accommodate all the
 * rendered text.
 *
 * @param {number} textHeight - height of the text, in pixels
 * @returns {number} the number of rows that the textarea
 * that renders on top of the text should have.
 */
export const computeRowsFromHeight = (textHeight: number): number => {
  return Math.max(1, textHeight / TEXTAREA_ROW_HEIGHT);
};

export type MessageInputProps = {
  photoInputRef: RefObject<HTMLInputElement>;
  fileInputRef: RefObject<HTMLInputElement>;
};

/**
 * The second version of the MessageInput component, for use with de-threading.
 * An input bar where the user composes messages. Depending on the consent status
 * of the transcript, the input bar may be blocked and will require the
 * merchant to request consent before being able to type and send.
 *
 * @example <MessageInput />
 * @param {RefObject} photoInputRef
 * A ref to the photo input used to upload photos.
 */
const MessageInput = observer(
  ({ photoInputRef, fileInputRef }: MessageInputProps): ReactElement => {
    const { t } = useTranslation();
    const {
      featureFlag,
      status,
      modal,
      user,
      transcriptView,
      navigation,
      tooltip,
      event,
      subscription,
    } = useMessengerControllerContext();
    const {
      transcript,
      message,
      setMessage,
      canFocusInput,
      confirmConsent,
      sendMessage,
      isInputDisabled,
      isPendingOrFailedNonretryableNotProhibited,
      isLoading,
      setCustomizedEmailSubject,
      emailSubject,
      isSubscribedToMPlus,
      isFailedRetryable,
      isMissingSubscription,
      isUnitInactive,
      hasUtterances,
    } = transcriptView;
    const { isSingleUnit, units, currentEmployee } = user;
    const selectedUnitName = units.get(transcript.sellerKey)?.name;
    const [showSubjectHeader, setShowSubjectHeader] = useState(false);
    const [isEditingSubject, setIsEditingSubject] = useState(false);

    const toggleOnSubjectHeader = (): void => {
      setShowSubjectHeader(true);
      setIsEditingSubject(false);
    };

    const toggleOnSubjectEdit = (): void => {
      setShowSubjectHeader(false);
      setIsEditingSubject(true);
    };

    // 1. populate the input placeholder e.g. 'Send via text'
    let placeholder =
      transcript.medium !== Medium.MEDIUM_UNRECOGNIZED
        ? t('MessageInput.sendViaPlaceholder', {
            medium: getMessageInputMediumLabel(transcript.medium),
          })
        : '';
    if (
      transcript.medium !== Medium.MEDIUM_UNRECOGNIZED &&
      selectedUnitName &&
      !isSingleUnit
    ) {
      placeholder = t('MessageInput.sendFrom', {
        medium: getMessageInputMediumLabel(transcript.medium),
        unit: selectedUnitName,
      });
    }

    // 2. Set up limits for photos
    const mediumFlags = featureFlag.getMediumFlags(transcript.medium);
    const photoCountLimit = mediumFlags.maxPhotosCount;
    const fileSizeLimit = mediumFlags.maxTotalFilesSizeBytes;

    // 3. Set up component and functions for photo/files upload
    const [localFiles, setLocalFiles] = useState<LocalFile[]>([]);
    // Reset selected photos/files when transcript changes
    useEffect(() => {
      setLocalFiles([]);
    }, [transcript.id]);

    const fileOnRemove = (file: LocalFile): void => {
      setLocalFiles(localFiles.filter((value) => value.url !== file.url));
    };

    const fileOnChange = (
      e: ChangeEvent<HTMLInputElement>,
      type: Attachment.AttachmentType,
    ): void => {
      const files: LocalFile[] = [];
      for (const file of e.target.files ?? []) {
        files.push({
          url: URL.createObjectURL(file),
          file,
          type,
        });
      }

      // eslint-disable-next-line no-param-reassign
      e.target.value = ''; // reset value so that we can select the same file again

      // Check if we exceed total file size limit
      if (type === Attachment.AttachmentType.FILE) {
        event.track('Select File Attach to Input', {
          filenames: files.map((file) => file.file.name),
          transcript_id: transcript.id,
        });

        const totalSize =
          getTotalFileSize(files) + getTotalFileSize(localFiles);
        if (totalSize > fileSizeLimit) {
          modal.openFileLimitReachedModal(
            fileSizeLimit,
            totalSize - fileSizeLimit,
          );

          return;
        }
      }

      setLocalFiles([...localFiles, ...files]);
    };

    const photoInput = (
      <input
        type="file"
        data-testid="MessageInput__photos__uploader"
        accept="image/png, image/jpeg"
        className="MessageInput__files__uploader"
        onChange={(e) => fileOnChange(e, Attachment.AttachmentType.IMAGE)}
        multiple={photoCountLimit > 1}
        ref={photoInputRef}
      />
    );
    const fileInput = (
      <input
        type="file"
        data-testid="MessageInput__files__uploader"
        accept="image/png, image/jpeg, image/gif, image/tiff, application/pdf"
        className="MessageInput__files__uploader"
        onChange={(e) => fileOnChange(e, Attachment.AttachmentType.FILE)}
        multiple
        ref={fileInputRef}
      />
    );

    useEffect(() => {
      const photosCount = localFiles.filter(
        (file) => file.type === Attachment.AttachmentType.IMAGE,
      ).length;
      if (photosCount > photoCountLimit) {
        status.setError({
          label: t('MessageInput.error.photosLimit', {
            count: photoCountLimit,
            medium: getMessageInputMediumLabel(transcript.medium),
          }),
        });
        setLocalFiles(localFiles.slice(0, photoCountLimit));
      }
      // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localFiles, photoCountLimit]);

    // 4. Focus on the input bar
    const inputRef = useRef<HTMLMarketTextareaElement>(null);
    // 4a. When canFocusInput is true, i.e. when the page is
    // done with the transition animation.
    // 4b. When message is updated, especially for the case when
    // smart reply insert text into the input bar
    // 4c. When photos are added/removed
    // 4d. Not when the email subject input is being focused
    useEffect(() => {
      if (canFocusInput && inputRef && inputRef.current && !isEditingSubject) {
        inputRef.current.focus();
      }
    }, [canFocusInput, message, localFiles, isEditingSubject]);

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    // Set textarea row count so height auto expands to fit content
    const [rows, setRows] = useState(1);
    useEffect(() => {
      if (textAreaRef && textAreaRef.current) {
        if (textAreaRef?.current?.style) {
          // Setting to zero ensures the browser updates the scrollHeight correctly for the following line
          // https://stackoverflow.com/questions/454202/creating-a-textarea-with-auto-resize
          textAreaRef.current.style.height = '0';
          textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
        setRows(computeRowsFromHeight(textAreaRef.current.scrollHeight));
      }
    }, [message]);

    // 5. A wrapper function to send a message and reset the input bar
    const isSendButtonDisabled =
      (message.trim() === '' && localFiles.length === 0) ||
      transcript.consentStatus === ConsentStatus.DENIED_DUE_TO_ERROR ||
      isInputDisabled ||
      isLoading;

    const sendMessageAndReset = async (): Promise<void> => {
      if (isSendButtonDisabled) {
        // Don't send message if in disabled state
        return;
      }

      // Track for user reliability metrics, intended to happen
      // right after message send is initiated, and before consent,
      // photo uploads, etc. happens
      const clientId = createUtteranceClientId(transcript.id);
      event.track('Start Outbound Merchant Message', {
        client_id: clientId,
        transcript_id: transcript.id,
        unit_token: transcript.sellerKey,
        context_utterance_id: transcript.lastViewItemUtteranceId ?? undefined,
      });

      let isConsentConfirmed = false;
      try {
        // Verify the user has consent to message this customer.
        // Note that consent is only confirmed for certain consent statuses, which is what isConsentConfirmed tracks
        isConsentConfirmed = await confirmConsent();
      } catch {
        // The user explicitly denied they have consent, abort sending message
        return;
      }

      sendMessage({
        message,
        localFiles,
        isConsentConfirmed,
        metadata: {
          clientId,
        },
      });

      setMessage('');
      setCustomizedEmailSubject(undefined);
      setLocalFiles([]);
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    };

    // 6. Construct send icon
    // disable button if input is empty (excludes whitespace) or if there are no
    // photos to upload
    const sendButton = (
      <MarketButton
        className="MessageInput__send"
        data-testid="MessageInput__send"
        onClick={sendMessageAndReset}
        disabled={isSendButtonDisabled || undefined}
        size="small"
      >
        <SendIcon slot="icon" />
      </MarketButton>
    );

    // 7. If email subject is being edited, focus on the email subject input.
    const emailSubjectInputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
      if (isEditingSubject && emailSubjectInputRef?.current) {
        emailSubjectInputRef.current.focus();
      }
    }, [isEditingSubject]);

    // 8. Clicking outside of the email-subject and text inputs (i.e. in the
    // other parts of the transcript) should hide the email header that appears
    // when the text input is focused. The reason we can't hide it in the `onBlur`
    // handler for the two-inputs div container is because the `onBlur` triggers
    // even when clicking inside the div container such as on the 'Edit subject'
    // button. To get around this, we check if a mouse click event registers outside
    // of the inputs and then use that to hide the email subject header.
    const inputsContainerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      const rootElement = getReactRoot() ?? document.body;

      const onMouseDown = (event: MouseEvent): void => {
        if (!inputsContainerRef?.current || !(event.target instanceof Node))
          return;

        // If mouse down is outside inputs, hide all email subject components
        if (!inputsContainerRef.current.contains(event.target)) {
          setShowSubjectHeader(false);
          setIsEditingSubject(false);
        }
      };

      // The reason 'mousedown' is used rather than 'click' is because the user
      // may click and drag to highlight the email subject, and the 'mouseup'
      // could happen outside the input
      rootElement.addEventListener('mousedown', onMouseDown);

      return () => {
        rootElement.removeEventListener('mousedown', onMouseDown);
      };
    }, []);

    // In blade mode, the space for the email subject is smaller so the "Edit subject"
    // button is shown at the end of the email subject text, rather than to the right
    const appendButtonToSubject = !navigation.secondary.isOpen;

    const editSubjectButton = (
      <span
        className={classNames(
          'MessageInput__email-subject-and-text-input__subject-label-card__edit',
          {
            'MessageInput__subject-button-appended': appendButtonToSubject,
          },
        )}
        data-testid="MessageInput__email-subject-and-text-input__subject-label-card__edit"
        tabIndex={0}
        onKeyDown={(e) => onKeyDownEnter(e, toggleOnSubjectEdit)}
        onMouseDown={(event: React.MouseEvent) => {
          event.stopPropagation();
          // This is necessary in order to stop the other 'mousedown' event listener from firing
          // Taken from https://stackoverflow.com/a/24421834
          event.nativeEvent.stopImmediatePropagation();
        }}
        onClick={() => {
          // This needs to be called here rather than in 'onMouseDown' because the click event
          // focuses the element. Otherwise, the focus does not get set correctly.
          toggleOnSubjectEdit();
        }}
      >
        <strong>{t('MessageInput.emailSubject.editButton')}</strong>
      </span>
    );

    // 9. Conditionally show a banner if there is a reason messages can't be sent.
    // The order of importance is as follows:
    // 1. Unit deactivated
    // 2. TFV Failed
    // 3. Customer unsubscribed
    // 4. Merchant blocked customer
    // 5. Consent request pending
    // 6. Pending TFV
    // 7. Prohibited
    // 8. M+ upgrade
    // 9. Non-M+ can only send as reply
    // 10. Unknown consent
    let contentAboveInputBar;
    if (!isLoading) {
      if (isUnitInactive) {
        contentAboveInputBar = (
          <InactiveBanner unitToken={transcript.sellerKey} />
        );
      } else if (isFailedRetryable && isMissingSubscription && hasUtterances) {
        contentAboveInputBar = (
          <VerificationFailedRetryableBanner
            unitTokens={[transcript.sellerKey]}
          />
        );
      } else if (transcript.consentStatus === ConsentStatus.REVOKED) {
        contentAboveInputBar = (
          <MarketBanner variant="info">
            {t('MessageInput.consent.revoked', {
              medium: getMessageInputMediumLabel(transcript.medium),
              unitName: selectedUnitName,
            })}
          </MarketBanner>
        );
      } else if (hasUtterances && transcript.isBlocked) {
        contentAboveInputBar = <BlockedBanner />;
      } else if (
        transcript.consentStatus === ConsentStatus.DENIED_CONSENT_REQUESTED
      ) {
        contentAboveInputBar = (
          <MarketBanner variant="info">
            {t('MessageInput.consent.requested')}
          </MarketBanner>
        );
      } else if (
        isPendingOrFailedNonretryableNotProhibited &&
        isMissingSubscription &&
        hasUtterances
      ) {
        contentAboveInputBar = <VerificationInProgressBanner />;
      } else if (
        isMissingSubscription &&
        subscription.isProhibited &&
        hasUtterances &&
        isInputDisabled
      ) {
        contentAboveInputBar = <IneligibleBanner />;
      } else if (isMissingSubscription && hasUtterances) {
        // Messages plus case, e.g. US merchants
        contentAboveInputBar = (
          <UpgradeBanner unitToken={transcript.sellerKey} />
        );
      } else if (
        transcript.consentStatus ===
          ConsentStatus.DENIED_SUBSCRIPTION_REQUIRED &&
        hasUtterances
      ) {
        // Non-Messages plus case, e.g. CA merchants
        contentAboveInputBar = (
          <MarketBanner variant="info">
            {t('MessageInput.consent.deniedSubscriptionRequired')}
          </MarketBanner>
        );
      } else if (
        transcript.consentStatus === ConsentStatus.DENIED_DUE_TO_ERROR ||
        transcript.consentStatus === ConsentStatus.CONSENT_STATUS_UNRECOGNIZED
      ) {
        contentAboveInputBar = (
          <MarketBanner variant="critical">
            {t('MessageInput.consent.deniedDueToError')}
          </MarketBanner>
        );
      }
    }

    const inputHasFiles = localFiles.some(
      (file) => file.type === Attachment.AttachmentType.FILE,
    );
    const inputBar = (
      <>
        <InputMenu
          photoInputRef={photoInputRef}
          fileInputRef={fileInputRef}
          inputHasPhotos={localFiles.some(
            (file) => file.type === Attachment.AttachmentType.IMAGE,
          )}
          inputHasFiles={inputHasFiles}
        />
        {currentEmployee.canManageOnlineCheckout && (
          <MarketButton
            data-testid="MessageInput__checkout-link"
            className="MessageInput__checkout-link-button"
            onClick={() => {
              modal.openCheckoutLinkModal();
            }}
            size="small"
            disabled={isInputDisabled || isLoading || undefined}
          >
            <CheckoutLinkIcon slot="icon" />
          </MarketButton>
        )}
        <div
          className={classNames('MessageInput__email-subject-and-text-input', {
            disabled: isInputDisabled,
          })}
          data-testid="MessageInput__email-subject-and-text-input"
          ref={inputsContainerRef}
        >
          {/* Email subject input bar */}
          {transcript.medium === Medium.EMAIL && isEditingSubject && (
            <>
              <MarketInputText
                className="MessageInput__email-subject-and-text-input__editable-subject-input"
                data-testid="MessageInput__email-subject-and-text-input__editable-subject-input"
                value={emailSubject}
              >
                <label>{t('MessageInput.emailSubject.label')}</label>
                <input
                  slot="input"
                  type="text"
                  value={emailSubject}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setCustomizedEmailSubject(e.target.value);
                  }}
                  ref={emailSubjectInputRef}
                  maxLength={MAX_EMAIL_SUBJECT_CHAR_LIMIT}
                />
              </MarketInputText>
              <MarketDivider
                className="MessageInput__email-subject-and-text-input__divider"
                data-testid="MessageInput__email-subject-and-text-input__divider"
                margin="small"
              />
            </>
          )}
          {/* Email subject header with 'Edit subject' button */}
          {transcript.medium === Medium.EMAIL && showSubjectHeader && (
            <MarketContentCard
              className="MessageInput__email-subject-and-text-input__subject-label-card"
              data-testid="MessageInput__email-subject-and-text-input__subject-label-card"
            >
              <span
                className={classNames(
                  'MessageInput__email-subject-and-text-input__subject-label-card__subject',
                  {
                    'MessageInput__subject-button-appended':
                      appendButtonToSubject,
                  },
                )}
                data-testid="MessageInput__email-subject-and-text-input__subject-label-card__subject"
              >
                <strong>
                  {t('MessageInput.emailSubject.labelWithInput', {
                    emailSubject,
                  })}
                </strong>
                {appendButtonToSubject && editSubjectButton}
              </span>
              {!appendButtonToSubject && editSubjectButton}
            </MarketContentCard>
          )}
          {/* Textarea input, including attached photos */}
          <MarketTextarea
            className="MessageInput__email-subject-and-text-input__market-textarea"
            data-testid="MessageInput__email-subject-and-text-input__market-textarea"
            value={message}
            onMarketTextareaValueChange={(
              event: MarketTextareaValueChangeEvent,
            ) => setMessage(event.detail.value)}
            placeholder={placeholder}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                sendMessageAndReset();
              }
            }}
            onFocus={() => {
              if (transcript.medium === Medium.EMAIL) {
                toggleOnSubjectHeader();
              }
            }}
            ref={inputRef}
            // Market applies a default max height value on the elements style directly which must be overridden here
            maxHeight={`${
              MAX_ROWS * TEXTAREA_ROW_HEIGHT + MAX_PHOTO_REEL_HEIGHT
            }px`}
            disabled={isInputDisabled || undefined}
            maxlength={mediumFlags.maxOutboundLengthChars.toString()}
          >
            {inputHasFiles && (
              <MarketInlineStatus className="MessageInput__file-status">
                {t('MessageInput.fileWarning')}
              </MarketInlineStatus>
            )}
            <AttachmentsReel files={localFiles} onRemove={fileOnRemove} />
            <textarea
              slot="textarea"
              className="MessageInput__email-subject-and-text-input__market-textarea__textarea"
              rows={rows}
              ref={textAreaRef}
              value={message}
              onChange={(e) => {
                e.preventDefault();
              }}
            />
          </MarketTextarea>
        </div>
        {sendButton}
        {photoInput}
        {fileInput}
      </>
    );

    return (
      <>
        {
          /* This should not show at the same time as MissingInfoBanner or VerificationInProgressBanner,
           * because `isInputDisabled` happens when consent is DENIED_BY_DEFAULT
           * which should not be the case if there's a missed call or voicemail (customer inbound) */
          featureFlag.get(KEY_MESSAGES_PLUS) &&
            isSubscribedToMPlus &&
            transcript.hasMissedCallOrVoicemail &&
            tooltip.isVisible('VOICEMAIL_CUSTOMIZATION_LAUNCH_BANNER') && (
              <VoicemailCustomizationLaunchBanner />
            )
        }
        {contentAboveInputBar}
        <div className="MessageInput" data-testid="MessageInput">
          {inputBar}
          {modal.currentModal === 'CONFIRM_CONSENT' && <ConfirmConsentModal />}
          {modal.currentModal === 'COUPON' && <SendCouponModal />}
          {modal.currentModal === 'CHECKOUT_LINK' && <CheckoutLinkModal />}
          {modal.currentModal === 'FILE_LIMIT_REACHED' && (
            <FileLimitReachedDialog />
          )}
        </div>
      </>
    );
  },
);

export default MessageInput;
