/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../bank-accounts/bank_accounts';
import '../privacyvault/annotations';
import '../common/time';
import '../common/token';
import '../common/location';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "teller": {
        "nested": {
          "BankAccount": {
            "fields": {
              "token": {
                "type": "string",
                "id": 1,
                "rule": "optional"
              },
              "bankAccountNumberFideliusToken": {
                "type": "string",
                "id": 2,
                "rule": "optional"
              },
              "accountName": {
                "type": "string",
                "id": 3,
                "rule": "optional"
              },
              "accountType": {
                "type": "squareup.bank_accounts.BankAccountType",
                "id": 4,
                "rule": "optional"
              },
              "vaultedData": {
                "type": "squareup.privacyvault.VaultedData",
                "id": 5,
                "rule": "optional"
              }
            }
          },
          "SentState": {
            "fields": {
              "fileTransmissionToken": {
                "type": "string",
                "id": 2,
                "rule": "optional"
              },
              "achTraceNumber": {
                "type": "string",
                "id": 3,
                "rule": "optional"
              },
              "achBatchNumber": {
                "type": "string",
                "id": 4,
                "rule": "optional"
              },
              "customerId": {
                "type": "string",
                "id": 5,
                "rule": "optional"
              },
              "valueDate": {
                "type": "squareup.common.time.YearMonthDay",
                "id": 6,
                "rule": "optional"
              },
              "isMysqlAuthoritative": {
                "type": "bool",
                "id": 7,
                "rule": "optional"
              },
              "pipelineRunId": {
                "type": "string",
                "id": 8,
                "rule": "optional"
              },
              "transmissionId": {
                "type": "string",
                "id": 9,
                "rule": "optional"
              },
              "workflowRunId": {
                "type": "string",
                "id": 10,
                "rule": "optional"
              }
            }
          },
          "FailureReason": {
            "values": {
              "INVALID_BANK_ACCOUNT": 1,
              "INVALID_TRANSFER_AMOUNT": 2,
              "RETURN": 3,
              "INVALID_ENTRY": 4,
              "MANUALLY_FAILED": 5,
              "EXCEEDS_CLIENT_TRANSFER_LIMIT": 6,
              "BANK_ACCOUNT_BLOCKED": 7,
              "INVALID_MECHANISM": 8,
              "CURRENCY_MISMATCHED": 9,
              "INVALID_ACCOUNT_HOLDER_NAME": 10,
              "REJECT": 11,
              "BANKING_PARTNER_OUTAGE": 12,
              "RECEIVING_BANK_UNAVAILABLE": 13,
              "UNKNOWN_FAILURE_REASON": 255
            }
          },
          "ReturnReason": {
            "values": {
              "INSUFFICIENT_FUNDS": 1,
              "PAYMENT_STOPPED": 7,
              "CUSTOMER_ADVISES_NOT_AUTHORIZED_OR_IMPROPER": 9,
              "CREDIT_ENTRY_REFUSED_BY_RECEIVER": 11,
              "UNAUTHORIZED_DEBIT_TO_CONSUMER_ACCOUNT_USING_CORPORATE_SEC_CODE": 21,
              "AUTHORIZATION_REVOKED_BY_CONSUMER": 22,
              "CORPORATE_CUSTOMER_ADVISES_NOT_AUTHORIZED": 23,
              "STOP_PAYMENT_ON_ITEM_RELATED_TO_RCK_ENTRY": 24,
              "OTHER": 12,
              "ACCOUNT_CLOSED": 2,
              "NO_ACCOUNT": 3,
              "INVALID_ACCOUNT_NUMBER": 4,
              "INVALID_ROUTING_NUMBER": 5,
              "ACCOUNT_FROZEN": 8,
              "NON_TRANSACTION_ACCOUNT": 10,
              "BENEFICIARY_OR_ACCOUNT_HOLDER_DECEASED": 15
            }
          },
          "FailedState": {
            "fields": {
              "failureReason": {
                "type": "squareup.teller.FailureReason",
                "id": 1,
                "rule": "optional"
              },
              "failureMessage": {
                "type": "string",
                "id": 2,
                "rule": "optional"
              },
              "returnReason": {
                "type": "squareup.teller.ReturnReason",
                "id": 3,
                "rule": "optional"
              },
              "returnFileToken": {
                "type": "string",
                "id": 4,
                "rule": "optional"
              },
              "rejectReason": {
                "type": "string",
                "id": 5,
                "rule": "optional"
              },
              "rejectFileToken": {
                "type": "string",
                "id": 6,
                "rule": "optional"
              }
            }
          },
          "ClientMetadata": {
            "fields": {
              "merchantToken": {
                "type": "string",
                "id": 1,
                "rule": "optional"
              },
              "omnibusMetadata": {
                "type": "squareup.teller.ClientMetadata.OmnibusMetadata",
                "id": 2,
                "rule": "optional"
              },
              "activity": {
                "type": "string",
                "id": 3,
                "rule": "optional"
              },
              "addendumPaymentRelatedInformation": {
                "type": "string",
                "id": 5,
                "rule": "optional"
              },
              "customerId": {
                "type": "string",
                "id": 6,
                "rule": "optional"
              },
              "discretionaryDataOrPaymentTypeCode": {
                "type": "string",
                "id": 7,
                "rule": "optional"
              },
              "debitMandate": {
                "type": "squareup.teller.ClientMetadata.DebitMandate",
                "id": 8,
                "rule": "optional"
              },
              "accountName": {
                "type": "string",
                "id": 9,
                "rule": "optional"
              },
              "p2pMetadata": {
                "type": "squareup.teller.ClientMetadata.P2PMetadata",
                "id": 10,
                "rule": "optional"
              },
              "vaultedData": {
                "type": "squareup.privacyvault.VaultedData",
                "id": 11,
                "rule": "optional"
              },
              "clientNameOverride": {
                "type": "string",
                "id": 12,
                "rule": "optional"
              }
            },
            "nested": {
              "OmnibusMetadata": {
                "fields": {
                  "billTokenPair": {
                    "type": "squareup.common.token.TokenPair",
                    "id": 1,
                    "rule": "optional"
                  },
                  "tenderTokenPair": {
                    "type": "squareup.common.token.TokenPair",
                    "id": 2,
                    "rule": "optional"
                  },
                  "moneyTransferServerToken": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  }
                }
              },
              "DebitMandate": {
                "fields": {
                  "id": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "signatureDate": {
                    "type": "squareup.common.time.DateTime",
                    "id": 2,
                    "rule": "optional"
                  }
                }
              },
              "P2PMetadata": {
                "fields": {
                  "senderAddress": {
                    "type": "squareup.common.location.GlobalAddress",
                    "id": 1,
                    "rule": "optional"
                  },
                  "recipientAddress": {
                    "type": "squareup.common.location.GlobalAddress",
                    "id": 2,
                    "rule": "optional"
                  },
                  "senderName": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "senderToken": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "paymentReference": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.teller?.nested?.BankAccount && !$root.nested?.squareup?.nested?.teller?.nested?.SentState && !$root.nested?.squareup?.nested?.teller?.nested?.FailureReason && !$root.nested?.squareup?.nested?.teller?.nested?.ReturnReason && !$root.nested?.squareup?.nested?.teller?.nested?.FailedState && !$root.nested?.squareup?.nested?.teller?.nested?.ClientMetadata && !$root.nested?.squareup?.nested?.teller?.nested?.ClientMetadata?.nested?.OmnibusMetadata && !$root.nested?.squareup?.nested?.teller?.nested?.ClientMetadata?.nested?.DebitMandate && !$root.nested?.squareup?.nested?.teller?.nested?.ClientMetadata?.nested?.P2PMetadata) {
	$root.addJSON(jsonData);
}

/**
 * Minimal amount of bank account information necessary for money transfers
 *
 * SOURCE BankAccount @ squareup/teller/teller.proto at 42:1
 */
export const BankAccount = $root.lookupType('squareup.teller.BankAccount');
fixType(BankAccount, {
  bankAccountNumberFideliusToken: { notEmpty: true },
  accountName: { notEmpty: true },
  accountType: { required: true },
});
Builder.createAndAttachToType(BankAccount);
/**
 * Detailed information for SENT and TENTATIVELY_SUCCEEDED state.
 *
 * Going forward, we’d like to have many gateways/routing mechanisms which would expose very
 * different metadata. Rather than model each gateway’s metadata as a new message and making
 * SentState/FailedState a tagged union, we decided to go for a more flexible model where
 * sent/failed states have a high level type and arbitrary key value data. Each type will have a
 * contract to populate some set of keys and the gateway might have a contract to add additional
 * information.
 *
 * SOURCE SentState @ squareup/teller/teller.proto at 94:1
 */
export const SentState = $root.lookupType('squareup.teller.SentState');
fixType(SentState);
Builder.createAndAttachToType(SentState);
/**
 * Gateway-agnostic failure reasons
 *
 * SOURCE FailureReason @ squareup/teller/teller.proto at 135:1
 */
export const FailureReason = $root.lookupEnum('squareup.teller.FailureReason').values;
/**
 * Return-specific failure reasons
 *
 * SOURCE ReturnReason @ squareup/teller/teller.proto at 184:1
 */
export const ReturnReason = $root.lookupEnum('squareup.teller.ReturnReason').values;
/**
 * Detailed information for FAILED state
 *
 * SOURCE FailedState @ squareup/teller/teller.proto at 246:1
 */
export const FailedState = $root.lookupType('squareup.teller.FailedState');
fixType(FailedState, {
  failureReason: { required: true },
});
Builder.createAndAttachToType(FailedState);
/**
 * The client-specific context around a transfer
 *
 * SOURCE ClientMetadata @ squareup/teller/teller.proto at 319:1
 */
export const ClientMetadata = $root.lookupType('squareup.teller.ClientMetadata');
fixType(ClientMetadata);
Builder.createAndAttachToType(ClientMetadata);
/**
 * SOURCE OmnibusMetadata @ squareup/teller/teller.proto at 323:3
 */
ClientMetadata.OmnibusMetadata = $root.lookupType('squareup.teller.ClientMetadata.OmnibusMetadata');
fixType(ClientMetadata.OmnibusMetadata);
Builder.createAndAttachToType(ClientMetadata.OmnibusMetadata);
/**
 * SOURCE DebitMandate @ squareup/teller/teller.proto at 370:3
 */
ClientMetadata.DebitMandate = $root.lookupType('squareup.teller.ClientMetadata.DebitMandate');
fixType(ClientMetadata.DebitMandate, {
  id: { notEmpty: true },
  signatureDate: { required: true },
});
Builder.createAndAttachToType(ClientMetadata.DebitMandate);
/**
 * SOURCE P2PMetadata @ squareup/teller/teller.proto at 408:3
 */
ClientMetadata.P2PMetadata = $root.lookupType('squareup.teller.ClientMetadata.P2PMetadata');
fixType(ClientMetadata.P2PMetadata, {
  senderAddress: { required: true },
  recipientAddress: { required: true },
  senderName: { notEmpty: true },
  senderToken: { notEmpty: true },
});
Builder.createAndAttachToType(ClientMetadata.P2PMetadata);
