import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { Trans, useTranslation } from 'react-i18next';
import { MarketButton } from 'src/components/Market';
import {
  MessengerModalFullContent,
  MessengerModalFullHeader,
} from 'src/components/MessengerModalFull';
import './UnitVerificationSuccessPage.scss';
import { getDashboardPricingAndSubscriptionsUrl } from 'src/utils/url';
import InProgressIcon from 'src/svgs/InProgressIcon';
import Link from 'src/components/Link/Link';

/**
 * Component that renders the success page in the V2 M+ Onboarding flow after submitting
 * a set of unit tokens for number verification.
 */
const UnitVerificationSuccessPage = observer((): ReactElement => {
  const { navigation, subscription, event } = useMessengerControllerContext();
  const { t } = useTranslation();

  useEffect(() => {
    event.track('View Successful Verification Submission');
  }, [event]);

  return (
    <>
      <MessengerModalFullHeader />
      <MessengerModalFullContent status={null}>
        <div className="UnitVerificationSuccessPage__content">
          <InProgressIcon />
          <h2 className="UnitVerificationSuccessPage__title">
            {t('UnitVerificationSuccessPage.title')}
          </h2>
          <p className="paragraph-30">
            <Trans i18nKey="UnitVerificationSuccessPage.description_one">
              {
                'Your information has been submitted to our 3rd party messaging partner for verification. Most verifications are completed within '
              }
              <span className="UnitVerificationSuccessPage__highlight">
                14 business days.
              </span>
            </Trans>
          </p>
          <p className="paragraph-30 UnitVerificationSuccessPage__description">
            <Trans i18nKey="UnitVerificationSuccessPage.description_two">
              {
                'Messages Plus will begin upon successful verification. You can check your verification status in '
              }
              <Link onClick={() => navigation.openSheet('SETTINGS')}>
                Settings
              </Link>
              {' and your account details in '}
              <Link
                onClick={() =>
                  window.open(getDashboardPricingAndSubscriptionsUrl, '_self')
                }
              >
                Pricing & Subscriptions
              </Link>
              {' once your plan starts.'}
            </Trans>
          </p>
          <MarketButton
            rank="primary"
            onClick={() => {
              event.track('Click Successful Verification Submission');
              subscription.onOnboardingComplete();
              navigation.closeSheet();
            }}
          >
            {t('UnitVerificationSuccessPage.button_label')}
          </MarketButton>
        </div>
      </MessengerModalFullContent>
    </>
  );
});

export default UnitVerificationSuccessPage;
